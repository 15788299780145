export const getMultiProgressBar = project => {
  return {
    backParts: [
      { label: 'minimum', value: 0 },
      {
        label: 'empty',
        value: 0,
      },
      {
        label: 'disabled',
        value: 100,
      },
    ],
    fillParts: [
      {
        label: 'privateSale',
        value: project.whitePercentage,
      },
      {
        label: 'publicSale',
        value: project.greenPercentage,
      },
      {
        label: 'softCapPercentage',
        value: project.softCapPercentage,
      },
    ],
    fill: 100,
  }
}

export default getMultiProgressBar
