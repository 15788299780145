import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ComponentWrapper,
  ComponentScroll,
  SectionTitle,
  StokrLoader,
} from '@stokr/components-library'
import TransactionInfo from 'components/TransactionInfo/TransactionInfo'
import TransactionDetails from 'components/TransactionDetails/TransactionDetails'
// import { FAQ } from '@stokr/components-library'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
// import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { OrderContext } from 'context/Order/OrderContext'
import fixDecimals from 'utils/fix-decimals'

const SignSubSendMetamask = ({ changeStep, tryAgain }) => {
  // const { exchangeRates } = useContext(ExchangeRatesContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { order } = useContext(OrderContext)
  const [shouldRenderPage, setShouldRenderPage] = useState(false)

  useEffect(() => {
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Invest in {project.name} </SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <SignSubText>
            <h3>SENDING FAILED</h3>
            <p>{'You rejected the transaction in MetaMask.'}</p>
            <p>
              Is your MetaMask connection installed and activated in your
              browser?
              <br />
              <br />
              Please check and try again.
            </p>
          </SignSubText>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <TransactionInfo
            value1={fixDecimals(order.currencyValue, user.currencyDecimals)}
            unit1={user.currencySymbol}
            name1={user.selectedAddress.name}
            owner1="(you)"
            address1={user.selectedAddress.address}
            value2={fixDecimals(
              order.tokenAmount,
              project.tokenRoundingDecimals,
            )}
            unit2={project.tokenSymbol}
            name2={project.tokenSymbol}
            owner2=""
            address2={user.selectedAddress.address}
            red
            redGreen
          />

          <TransactionDetails
            data={[
              {
                label: 'account balance',
                value: `${user.selectedAddress?.currencybalance} ${user.currencySymbol}`,
                valueInfo: '(before investment)',
              },
              {
                label: 'gas limit',
                value: `${order.txData.gasLimit}`,
              },
              {
                label: 'gas price',
                value: `${order.gasPrice / 1e9} GWEI (${
                  order.gasPrice / 1e18
                } ETH)`,
              },
              {
                label: 'max tx fee',
                value: `${fixDecimals(
                  (order.gasPrice * (order.txData && order.txData.gasLimit)) /
                    1e18,
                  4,
                )} ETH (${
                  (order.gasPrice * order.txData.gasLimit) / 1e9
                } GWEI)`,
              },
            ]}
          />
        </ComponentWrapper>

        <ComponentWrapper center>
          <Button minWidth="240px" onClick={tryAgain}>
            Try again
          </Button>
        </ComponentWrapper>

        {/* <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'Question 1',
                content:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat.',
              },
              {
                title: 'Question 2',
                content:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat.',
              },
              {
                title: 'Question 3',
                content:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat.',
              },
            ]}
          />
        </ComponentWrapper> */}
      </ComponentScroll>
    </ModalWrapper>
  ) : (
    <StokrLoader />
  )
}

SignSubSendMetamask.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default SignSubSendMetamask
