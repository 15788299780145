import React from 'react'
import { useNavigate } from 'react-router-dom'

// export const navigate = (url, ...args) => {
//   let rrNavigate = useNavigate()

//   if (url.startsWith('/')) {
//     return rrNavigate(url + window.location.search, ...args)
//   } else {
//     return rrNavigate(url)
//   }
// }

export const useCustomNav = () => {
  const navigation = useNavigate()

  const navigate = (url, args = {}) => {
    if (url.startsWith('/')) {
      return navigation(url + window.location.search, args)
    } else {
      return navigation(url, args)
    }
  }

  return { navigate }
}

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigation = useNavigate()

    const navigate = (url, ...args) => {
      if (url.startsWith('/')) {
        return navigation(url + window.location.search, ...args)
      } else {
        return navigation(url)
      }
    }

    return <Component navigate={navigate} {...props} />
  }

  return Wrapper
}
