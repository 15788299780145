import styled from 'styled-components/macro'
import { Collapse } from 'react-collapse'

export const Container = styled.div`
  display: block;
  position: relative;
  text-align: center;
  padding-top: 8px;
`

export const Toggle = styled.button`
  display: inline-block;
  font-size: 11px;
  line-height: 16px;
  color: ${props => props.theme.cGrey2};
  transition: color 0.2s;
  outline: 0;

  &:hover {
    color: ${props => props.theme.cPrimary};
  }
`

export const DetailsCollapse = styled(Collapse)`
  opacity: 0;
  transition: opacity 0.2s;
  margin-top: 12px;

  ${props =>
    props.isOpened &&
    `
    opacity: 1;
    border-bottom: 1px solid ${props.theme.cLightGrey};
  `}
`
