import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ComponentWrapper,
  ComponentScroll,
  FAQ,
  SectionTitle,
  StokrLoader,
} from '@stokr/components-library'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
import { OrderContext } from 'context/Order/OrderContext'
import { LabelWarning } from 'components/BasicTable/BasicTable.styles'
import { ProjectContext } from 'context/Project/ProjectContext'
import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'

const SignSubIntro = ({ setWallet, changeStep }) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  let { order } = useContext(OrderContext)
  let { project } = useContext(ProjectContext)
  let { bitFinexPayLimit } = useContext(ExchangeRatesContext)

  useEffect(() => {
    setShouldRenderPage(true)

    // Tomporarily disabled metamask flow 09/2021 and direct user to BFX Pay
    setWallet('bitfinex')
    changeStep()
    // Delete this to enable MM again
  }, [])

  return shouldRenderPage ? (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Invest in {project.name}</SectionTitle>
      </ComponentWrapper>

      <ComponentScroll>
        <ComponentWrapper noPaddingVertical>
          <SignSubText>
            <h3>CHOOSE YOUR PAYMENT METHOD</h3>
            <p>
              {
                'In case you have USDT (ERC20) in your Metamask account, choose Metamask. If you are using any other exchange or wallet, choose Bitfinex Pay.'
              }
            </p>
            <p>
              {!window.ethereum &&
                'You can install the MetaMask extension in order to make the investment.'}
            </p>
          </SignSubText>
        </ComponentWrapper>

        {
          <ComponentWrapper center noPaddingBottom>
            <Button
              //fluid

              minWidth="240px"
              onClick={() => {
                setWallet('bitfinex')
                changeStep()
              }}
              disabled={order.currencyValue > bitFinexPayLimit}
            >
              Bitfinex Pay
            </Button>
            {order.currencyValue > bitFinexPayLimit && (
              <LabelWarning>
                Bitfinex Pay is only available for payments less than{' '}
                {bitFinexPayLimit} $
              </LabelWarning>
            )}
          </ComponentWrapper>
        }

        <ComponentWrapper center>
          {showRefreshButton ? (
            <Button
              //fluid
              minWidth="240px"
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh
            </Button>
          ) : !window.ethereum ? (
            <Button
              //fluid
              minWidth="240px"
              onClick={() => {
                window.open('https://metamask.io', '_blank')
                setShowRefreshButton(true)
              }}
            >
              Get Metamask
            </Button>
          ) : (
            <Button
              //fluid
              minWidth="240px"
              onClick={() => {
                setWallet('metamask')
                changeStep()
              }}
            >
              Metamask
            </Button>
          )}
        </ComponentWrapper>
        <ComponentWrapper noPaddingBottom borderTop>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'When to use BITFINEX PAY?',
                content:
                  'Bitfinex Pay will allow greater flexibility for how you want to pay with your USDT (ERC20). If you hold USDT in an account other than Metamask, this will be the best option. You can connect any wallet or exchange that holds your USDT.',
              },
              /*   {
                title: 'When to use METAMASK?',
                content: (
                  <>
                    Use Metamask if you have a Metamask account and wallet setup
                    with funds ready to go. You can install a plugin that makes
                    the whole process quite simple. If you want more information
                    on how to use it with STOKR,{' '}
                    <a
                      href="https://www.youtube.com/watch?v=wpWbHEiqI_8"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'initial' }}
                    >
                      please see this video
                    </a>
                    .{' '}
                    <strong>
                      Please be aware that transaction fees will apply and you
                      will need an ETH balance for the transaction to be
                      successful.
                    </strong>
                  </>
                ),
              }, */
            ]}
          />
        </ComponentWrapper>
      </ComponentScroll>
    </ModalWrapper>
  ) : (
    <StokrLoader />
  )
}

SignSubIntro.propTypes = {
  setWallet: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
}

export default SignSubIntro
