import React, { useContext } from 'react'
import CountUp from 'react-countup'
import PropTypes from 'prop-types'
import MultiProgressBar, {
  MultiProgressBarPropTypes,
} from 'components/MultiProgressBar/MultiProgressBar'
import { Wrapper, Title, Amount } from './CaptialRaisedSummary.styles'
import { ProjectContext } from 'context/Project/ProjectContext'

const CaptialRaisedSummary = ({ capital, multiProgressBar }) => {
  let { project } = useContext(ProjectContext)

  return (
    <Wrapper>
      <Title>CAPITAL RAISED</Title>
      <Amount>
        {capital ? (
          <CountUp
            end={capital}
            prefix={project.denominationSymbol}
            duration={0.01}
            separator=","
          />
        ) : (
          '-'
        )}
      </Amount>
      <MultiProgressBar {...multiProgressBar} />
    </Wrapper>
  )
}

CaptialRaisedSummary.propTypes = {
  capital: PropTypes.number.isRequired,
  multiProgressBar: PropTypes.shape(MultiProgressBarPropTypes).isRequired,
}

export default CaptialRaisedSummary
