import styled from 'styled-components/macro'
import { SelectControl } from 'components/Input/Select.styles'
import { FormError } from 'components/Form/Form'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Container = styled.div``

export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  color: ${props => props.theme.cBlack};
  text-transform: uppercase;

  ${props => props.error && `color: ${props.theme.cWarning};`}
`

export const InputWrap = styled.div`
  padding-top: 9px;

  input {
    font-size: 22px;
    font-weight: 300;
    line-height: 28px;
    padding-bottom: 11px;
    padding-top: 0;
  }
`

export const StyledFormError = styled(FormError)`
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-style: italic;
  top: 0;

  width: min-content;
  min-width: 100%;

  ${props => props.paddingTop && 'padding-top: 12px;'}
`

export const Caption = styled.div`
  // font-size: 9px;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  padding-top: 12px;
  letter-spacing: 0.4px;
  font-style: italic;
`

export const EqualSign = styled.div`
  margin: auto;
  font-size: 34px;
  font-weight: 300;
  color: ${props => props.theme.cBlack};
  opacity: 0.5;
  padding: 0px 30px;
`

export const SelectWrapper = styled.div`
  padding-bottom: 2px;

  ${SelectControl}${SelectControl}${SelectControl} {
    padding: 0;
    border: 0;
    height: 24px;
    min-height: 24px;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2px;
    margin-top: -2px;
  }
`

export const Label = styled.div`
  ${props => props.error && `color: ${props.theme.cWarning};`}
`
