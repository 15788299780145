import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components/macro'
import GlobalStyle from 'styles/global'
import theme from 'styles/theme'
import {
  PageWrapper,
  Footer,
  Header,
  AuthConsumer,
} from '@stokr/components-library'
import { UserConsumer } from 'context/User/UserContext'

const Layout = ({
  title,
  progress,
  loginHandler,
  signupHandler,
  signupFlow,
  children,
  footerColor,
  noHeader,
  noFooter,
}) => (
  <ThemeProvider theme={{ ...theme }}>
    <PageWrapper>
      <GlobalStyle />
      {!noHeader && (
        <AuthConsumer>
          {({ logoutUser, user }) => (
            <UserConsumer>
              {({ avatar }) => {
                return (
                  <Header
                    progress={progress}
                    signupFlow={signupFlow}
                    user={user}
                    userPhoto={avatar}
                    logoutUser={logoutUser}
                  />
                )
              }}
            </UserConsumer>
          )}
        </AuthConsumer>
      )}
      {children}
      {!noFooter && <Footer color={footerColor} />}
    </PageWrapper>
  </ThemeProvider>
)

Layout.propTypes = {
  title: PropTypes.string,
  signupFlow: PropTypes.bool,
  loginHandler: PropTypes.func,
  signupHandler: PropTypes.func,
  progress: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    doneIndex: PropTypes.number,
    activeIndex: PropTypes.number,
  }),
  footerColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  title: '',
  progress: null,
  signupFlow: false,
  footerColor: 'red',
  loginHandler: () => {},
  signupHandler: () => {},
}

export default Layout
