import axios from 'axios'
import { BASE_URL } from 'utils/base-api-urls'

class CheckboxClass {
  static getCheckbox(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/compliance/get-checkboxes`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static getCheckboxGroup(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/compliance/get-checkbox-group`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static checkCheckbox(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/compliance/check-checkbox`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default CheckboxClass
