import React, { useEffect, useState, useContext } from 'react'
import {
  Row,
  Column,
  InvestmentStat,
  ImageSlide,
  ImageSlideWrapper,
  Slider,
} from '@stokr/components-library'
import CaptialRaisedSummary from 'components/CapitalRaisedSummary/CaptialRaisedSummary'
import parseBlockContent from 'utils/parseBlockContent'
import { ProjectContext } from 'context/Project/ProjectContext'
import { Video, PlayButton } from 'components/video'
import { ProjectTypes } from 'constants/enums'
import playButton from 'components/video/img/play-btn.svg'

export const InfoPanel = (params) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const [carouselItems, setCarouselItems] = useState([])
  let { project } = useContext(ProjectContext)
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [videoUri, setVideoUri] = useState('')

  useEffect(() => {
    const carouselFiles = generateCarouselItems(project.blocks.media)
    setCarouselItems(carouselFiles)
    setShouldRenderPage(true)
  }, [])

  const generateCarouselItems = (media) => {
    const mediaParsed = parseBlockContent(media)

    const carouselFiles = []
    const tempObj = {}

    // Find only carousel items
    Object.keys(mediaParsed).forEach((key) => {
      if (key.includes('carouselFiles')) {
        const index = key.split('carouselFiles_')[1].split('_')[0]

        // Group video and its thumbnail
        if (key.includes('videoThumbnail')) {
          tempObj[index] = {
            ...tempObj[index],
            videoThumbnail: mediaParsed[key],
          }
        } else {
          tempObj[index] = { ...tempObj[index], ...mediaParsed[key] }
        }
      }
    })

    Object.keys(tempObj).forEach((key) => {
      carouselFiles.push(tempObj[key])
    })

    return carouselFiles
  }

  const openVideo = (videoUri) => {
    setIsVideoOpen(true)
    setVideoUri(videoUri)
  }

  const closeVideo = () => {
    setIsVideoOpen(false)
    setVideoUri()
  }

  return shouldRenderPage ? (
    <Row>
      <Video
        src={videoUri}
        noPlayButton
        play={isVideoOpen}
        onClose={closeVideo}
      />
      <Column part={2} full={3}>
        <Slider dots>
          {carouselItems.map((item) => {
            if (item.type === 'image') {
              return (
                <ImageSlideWrapper key={item.uri}>
                  <ImageSlide src={item.uri} alt="" />
                </ImageSlideWrapper>
              )
            } else if (item.type === 'video') {
              return (
                <ImageSlideWrapper key={item.uri}>
                  <ImageSlide src={item.videoThumbnail.uri} alt="" />
                  <PlayButton onClick={() => openVideo(item.uri)}>
                    <img alt="Play video" src={playButton} />
                  </PlayButton>
                </ImageSlideWrapper>
              )
            }
          })}
        </Slider>
        <CaptialRaisedSummary
          capital={project.totalSold}
          multiProgressBar={project.multiProgressBar}
        />
      </Column>

      <Column part={1} full={3}>
        <InvestmentStat
          content={project.sharePercentage}
          title={project.shareType}
          tooltip={
            project.name === 'aquarius'
              ? 'The target yield per annum in accordance with the Offering Memorandum. Please read carefully the Offering Memorandum before investing'
              : project.name === 'mazzanti'
              ? 'The Securities represent the right to 50% of the sale proceeds for the sale of 1 (one) Millecavalli R car (minus any taxes).'
              : project.name === 'blockstream-mining'
              ? 'The percentage represents all Bitcoins mined over a period of 36 months in accordance with the Terms and Conditions. Please carefully read the Terms and Conditions before investing.'
              : `The percentage of the venture’s future ${
                  project.name === 'infinite-fleet' ? 'profits' : 'revenues'
                } to be distributed in accordance with the Offering Terms. Please carefully read the Offering Terms and Offering Document
           before investing.`
          }
          borderTop
        />
        <InvestmentStat
          content={
            project.type === ProjectTypes.FUND
              ? 'OPEN-ENDED'
              : project.name === 'infinite-fleet'
              ? 'TBD'
              : project.daysLeft
          }
          title={
            project.type === ProjectTypes.FUND
              ? 'fund type'
              : project.daysLeftText
          }
          borderTop
        />
        <InvestmentStat
          content={
            project.type === ProjectTypes.FUND
              ? 'PROFESSIONAL'
              : project.investmentCount.toString()
          }
          title="Investors"
          primaryBg
        />
      </Column>
    </Row>
  ) : (
    <></>
  )
}

export default InfoPanel
