import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from './Progress'

const MultiProgressBar = ({ backParts, fillParts, fill }) => (
  <>
    <Progress
      disabled={100} // hard cap
      progressStyle={true}
      inactive={false}
      data={fillParts}
    />
  </>
)

export const MultiProgressBarPropTypes = {
  backParts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fillParts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fill: PropTypes.number.isRequired,
}

MultiProgressBar.propTypes = MultiProgressBarPropTypes

export default MultiProgressBar
