import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import * as sc from 'constants/style'
import progressBg from './emptyProgress.svg'

const widthHelper = ({ width = 0 }) => `${width}%`
const heightHelper = ({ height = 8 }) => `${height}px`

const StyledWrapper = styled.div`
  display: flex;
  height: 8px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  background: ${sc.BLUE_BASE_RELEASED_HEX};
  ${({ progressStyle }) => progressStyle}

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.3;
    `}
`

const StyledPrivateInvest = styled.div`
  background: transparent;
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
  border-radius: 12px 0 0 12px;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px 0 0 12px;
    background-image: linear-gradient(to left, #fff, #fff);
    transition: transform 2.5s 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: left top;
    transform: scaleX(${({ animation }) => (animation ? 1 : 0)});
  }
`

const StyledSuccess = styled.div`
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 12px 12px 0;
    background-image: linear-gradient(to left, #a9ef9c, #69bd53);
    transition: transform 2.5s 2.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: left top;
    transform: scaleX(${({ animation }) => (animation ? 1 : 0)});
  }
`

const StyledEmpty = styled.div`
  flex: 0 0 ${widthHelper};
`

const StyledDisabled = styled.div`
  background: url(${progressBg}) repeat bottom;
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
`

export class Progress extends PureComponent {
  static propTypes = {
    privateInvestment: PropTypes.number,
    success: PropTypes.number,
    empty: PropTypes.number,
    disabled: PropTypes.number,
  }

  state = {
    animation: false,
  }

  handleVisibilityChange = (isVisible) => {
    const { animation } = this.state

    if (!animation) {
      setTimeout(() => this.setState({ animation: isVisible }))
    }
  }

  render() {
    const { disabled, progressStyle, inactive, data } = this.props
    const { animation } = this.state

    let privateInvestment
    let publicInvestment
    let softCapPercentage

    data.forEach(function (arrayItem) {
      if (arrayItem.label === 'privateSale') {
        privateInvestment = arrayItem.value
      } else if (arrayItem.label === 'publicSale') {
        publicInvestment = arrayItem.value
      } else if (arrayItem.label === 'softCapPercentage') {
        softCapPercentage =
          arrayItem.value - privateInvestment - publicInvestment
      }
    })

    return (
      <VisibilitySensor onChange={this.handleVisibilityChange}>
        <StyledWrapper progressStyle={progressStyle} inactive={inactive}>
          <StyledPrivateInvest
            width={privateInvestment}
            animation={animation}
          />
          <StyledSuccess width={publicInvestment} animation={animation} />
          <StyledEmpty width={softCapPercentage} />
          <StyledDisabled width={disabled} />
        </StyledWrapper>
      </VisibilitySensor>
    )
  }
}

export default Progress
