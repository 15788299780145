import styled from 'styled-components/macro'

export const Container = styled.div`
  display: block;
`

export const CheckboxWrap = styled.div`
  padding: 6px 0;
`

export const InfoBox = styled.div`
  max-width: 240px;
  text-align: left;
`

export const OfferingPageLink = styled.a`
  text-decoration: underline;

  &:hover {
    color: #0050ca !important;
    opacity: 0.7;
    text-decoration: underline;
  }
`

export const TermsOfOfferingLink = styled.a`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`
