import styled, { css } from 'styled-components/macro'

export const SwitchInTitleWrapper = styled.div`
  height: 20px;
  margin: -1px 0;

  ${props =>
    props.right &&
    css`
      float: right;
    `}

  ${props =>
    props.left &&
    css`
      float: left;
    `}
`

export default SwitchInTitleWrapper
