import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Account,
  TransferIcon,
  Value,
  Name,
  Caption,
} from './TransactionInfo.styles'

const TransactionInfo = ({
  value1,
  unit1,
  name1,
  owner1,
  value2,
  unit2,
  name2,
  owner2,
  green,
  red,
  leftCaption,
  rightCaption,
  redGreen,
}) => (
  <Container>
    <Account>
      <Name green={green} red={red}>{`${name1} ${owner1}`}</Name>
      <Value green={green} red={red}>{`${value1} ${unit1}`}</Value>
      <Caption> {leftCaption}</Caption>
    </Account>
    <TransferIcon />
    <Account>
      <Name
        red={red}
        redGreen={redGreen}
        green={green}
      >{`${name2} ${owner2}`}</Name>
      <Value
        red={red}
        redGreen={redGreen}
        green={green}
      >{`${value2} ${unit2}`}</Value>
      <Caption> {rightCaption}</Caption>
    </Account>
  </Container>
)

TransactionInfo.propTypes = {
  value1: PropTypes.string,
  unit1: PropTypes.string,
  name1: PropTypes.string,
  owner1: PropTypes.string,
  value2: PropTypes.string,
  unit2: PropTypes.string,
  name2: PropTypes.string,
  owner2: PropTypes.string,
  leftCaption: PropTypes.string,
  rightCaption: PropTypes.string,
}

TransactionInfo.defaultProps = {
  value1: '',
  unit1: '',
  name1: '',
  owner1: '',
  value2: '',
  unit2: '',
  name2: '',
  owner2: '',
  leftCaption: '',
  rightCaption: '',
}

export default TransactionInfo
