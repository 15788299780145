import styled, { css } from 'styled-components/macro'
import colors from 'styles/_colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Account = styled.div``

export const Caption = styled.div`
  font-size: 11px;
  font-weight: 300;
  padding-top: 5px;
  letter-spacing: 0.4px;
  //font-style: italic;
`

export const TransferIcon = styled.div.attrs({
  className: 'ion',
})`
  width: 16px;
  height: 19px;
  position: relative;
  margin: auto;

  &::before,
  &::after {
    font-size: 28px;
    position: absolute;
    left: 0;
  }

  &::before {
    content: '\f117';
    color: ${props => props.theme.cSuccess};
    top: -7px;
  }

  &::after {
    content: '\f119';
    color: ${props => props.theme.cWarning};
    top: 2px;
  }
`

export const Value = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.8px;

  ${props =>
    props.green &&
    css`
      color: ${colors.progressGreen};
    `}

  ${props =>
    props.red &&
    css`
      color: ${colors.orangishRed};
    `}

     ${props =>
       props.redGreen &&
       css`
         color: ${colors.progressGreen};
       `}
`

export const Name = styled.div`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-style: italic;
  margin-bottom: 3px;

  ${props =>
    props.green &&
    css`
      color: ${colors.progressGreen};
    `}

  ${props =>
    props.red &&
    css`
      color: ${colors.orangishRed};
    `}

    ${props =>
      props.redGreen &&
      css`
        color: ${colors.progressGreen};
      `}
`
