export default networkId => {
  let networkName
  switch (networkId) {
    case '1':
      networkName = 'Ethereum Main Net'
      break
    case '2':
      networkName = 'Morden Test Net'
      break
    case '3':
      networkName = 'Ropsten Test Net'
      break
    case '4':
      networkName = 'Rinkeby Test Net'
      break
    case '5':
      networkName = 'Görli Test Net'
      break
    case '42':
      networkName = 'Kovan Test Net'
      break
    default:
      networkName = 'Unknown/Custom RPC Net'
  }
  return networkName
}
