import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  ComponentWrapper,
  ComponentScroll,
  CryptoAddressWrapper,
  SectionTitle,
  StokrLoader,
  CryptoAddress,
} from '@stokr/components-library'
import TransactionInfo from 'components/TransactionInfo/TransactionInfo'
import TransactionDetails from 'components/TransactionDetails/TransactionDetails'
import Form from 'components/Form/Form'
import AgreementItem from 'components/AgreementItem/AgreementItem'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
import { useCustomNav } from 'utils/customNavigate'
import fixDecimals from 'utils/fix-decimals'
import sendEmailToAdmin from 'utils/sendEmailToAdmin'
import { getMetamaskAddress } from 'utils/walletutils'
import * as urls from 'constants/paths'
import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { OrderContext } from 'context/Order/OrderContext'

const SignSubTransactionMetamask = (props) => {
  const { exchangeRates, updateEtherPrice } = useContext(ExchangeRatesContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { order, storeInvestment, updateInvestment, updateOrder } =
    useContext(OrderContext)
  const navigator = useCustomNav()

  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const [isConfirmOnMetaMask, setIsConfirmOnMetaMask] = useState(false)
  const [etherPriceChanged, setEtherPriceChanged] = useState(false)
  const [oldTokenAmount, setOldTokenAmount] = useState()

  const initialValues = {
    paying: false,
    token: false,
    send: false,
  }

  const validationSchema = Yup.object().shape({
    token: Yup.bool().oneOf([true], 'Agree is required'),
  })

  useEffect(() => {
    handleAccountsChange()
    checkAddress()
    makeTransaction()
  }, [])

  const handleAccountsChange = async () => {
    window.ethereum.on('accountsChanged', async () => {
      const metamaskAddress = await getMetamaskAddress()
      if (
        metamaskAddress.toString().toLowerCase() !==
        user.selectedAddress.address.toLowerCase()
      ) {
        // Ready to render the page
        setShouldRenderPage(true)
        const { goBack } = props
        goBack()
      }
    })
  }
  const checkAddress = async () => {
    const metamaskAddress = await getMetamaskAddress()
    if (
      metamaskAddress.toString().toLowerCase() !==
      user.selectedAddress.address.toLowerCase()
    ) {
      props.changeStep()
    }
  }
  const makeTransaction = async () => {
    if (false) {
      // alert('ERROR: TX WILL FAIL SignSubTransactionMetamask')
      console.log('order: ', order)
      navigator.navigate(urls.OOPS, {
        state: {
          message: `Please make sure that you have enough ${
            user.currencySymbol
          } to 
          cover the transaction for ${parseInt(order.tokenAmount).toFixed(
            project.tokenRoundingDecimals,
          )} ${project.tokenSymbol} (at least ${parseInt(
            order.currencyValue,
          ).toFixed(project.tokenRoundingDecimals)} ${user.currencySymbol})
          as well as enough ETH to cover the transaction fees.`,
        },
      })
      return
    }

    // Ready to render the page
    setShouldRenderPage(true)
  }

  const signTransaction = async () => {
    setIsConfirmOnMetaMask(true)

    let investmentId

    try {
      const investment = await storeInvestment({
        project: project._id,
        tokenUID: project.secondaryAssetId,
        isPrivateSale: user.isPrivateSale,
        tokenAmount: order.tokenAmount,
        tokenPrice: order.tokenPrice,
        currencyType: user.currencyInternalReference,
        currencyAmount: order.currencyValue,
        ethAddress: user.selectedAddress.address,
        GAID: project.isLiquid ? user.GAID : '',
        tokenName: project.tokenName,
        tokenSymbol: project.tokenSymbol,
        investorTxStatus: 'Started',
      })
      investmentId = investment._id
    } catch (error) {
      console.error(error)
      navigator.navigate(urls.OOPS, {
        state: {
          message: 'We are sorry, your session expired, Please log in again.',
          tokenExpired: true,
          ctaLabel: 'GO TO LOGIN',
        },
      })
      return
    }
    let txHash

    try {
      const result = await new Promise((resolve, reject) => {
        window.ethereum.sendAsync(
          {
            method: 'eth_sendTransaction',
            params: [order.txData],

            from: user.selectedAddress.address,
          },
          (error, result) => {
            if (error) {
              reject(error)
            } else {
              resolve(result)
            }
          },
        )
      })

      txHash = result.result
    } catch (error) {
      console.error(error)
      try {
        await updateInvestment(investmentId, 'Failed')
      } catch (error) {
        console.error(error)
      }
      if (error.code === 4001) {
        // User rejected on MetaMask
        setIsConfirmOnMetaMask(false)
      } else {
        navigator.navigate(urls.OOPS, {
          state: {
            message:
              'There was a problem with MetaMask while signing the transaction.',
          },
        })
      }
      return
    }

    if (!txHash) {
      try {
        await updateInvestment(investmentId, 'Failed')
      } catch (error) {
        console.error(error)
      }
      navigator.navigate(urls.OOPS, {
        state: {
          message:
            'There was a problem with MetaMask while signing the transaction.',
        },
      })
      return
    }
    try {
      await updateInvestment(investmentId, 'Done', txHash)
    } catch (error) {
      sendEmailToAdmin(
        'Storing TXHash in DB failed',
        txHash + '<br>' + investmentId + '<br>' + error,
      )
    }
    navigator.navigate(urls.SUCCESS)
  }

  const abortOnEtherRateChange = () => {
    navigator.navigate(urls.INVEST)
  }

  const onSubmit = async () => {
    if (user.currencyInternalReference === 'ether') {
      const oldEtherPrice = exchangeRates.pricesinEURorUSD.ETH
      const newEtherPrice = await updateEtherPrice()

      if (newEtherPrice !== oldEtherPrice) {
        setOldTokenAmount(order.tokenAmount)

        updateOrder({
          tokenAmount: order.currencyValue * newEtherPrice,
        })

        // Informs parent, so behaviour of back and close button change
        setEtherPriceChanged(true)
      } else {
        signTransaction()
      }
    } else {
      signTransaction()
    }
  }

  return shouldRenderPage ? (
    <ModalWrapper>
      <ComponentWrapper>
        <SectionTitle>Invest in {project.name}</SectionTitle>
      </ComponentWrapper>

      {etherPriceChanged ? (
        <ComponentScroll>
          <ComponentWrapper noPaddingVertical>
            <SignSubText>
              <h3>ETHER RATE CHANGED</h3>
              <p>
                The ether rate changed since you started the investment process.
                Please review the new rate below and let us know if you want to
                proceed.
              </p>
            </SignSubText>
          </ComponentWrapper>
          <br />
          <br />

          <ComponentWrapper noPaddingVertical>
            <SectionTitle>Before</SectionTitle>
          </ComponentWrapper>
          <ComponentWrapper noPaddingBottom={!isConfirmOnMetaMask} noPaddingTop>
            <TransactionInfo
              value1={fixDecimals(order.currencyValue, user.currencyDecimals)}
              unit1={user.currencySymbol}
              name1={user.selectedAddress.name}
              owner1="(you)"
              address1={user.selectedAddress.address}
              value2={fixDecimals(
                oldTokenAmount,
                project.tokenRoundingDecimals,
              )}
              unit2={project.tokenSymbol}
              name2={project.tokenSymbol}
              owner2=""
              address2={user.selectedAddress.address}
              red
              redGreen
            />

            <TransactionDetails data={[]} />
          </ComponentWrapper>

          <br />
          <br />

          <ComponentWrapper noPaddingVertical>
            <SectionTitle>Now</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom={!isConfirmOnMetaMask} noPaddingTop>
            <TransactionInfo
              value1={fixDecimals(order.currencyValue, user.currencyDecimals)}
              unit1={user.currencySymbol}
              name1={user.selectedAddress.name}
              owner1="(you)"
              address1={user.selectedAddress.address}
              value2={fixDecimals(
                order.tokenAmount,
                project.tokenRoundingDecimals,
              )}
              unit2={project.tokenSymbol}
              name2={project.tokenSymbol}
              owner2=""
              address2={user.selectedAddress.address}
              green
            />

            <TransactionDetails
              data={[
                {
                  label: 'token value in euro',
                  value: `${project.denominationSymbol} ${fixDecimals(
                    order.tokenPrice * order.tokenAmount,
                    project.euroDecimals,
                  )}`,
                  valueInfo: '(before investment)',
                },
                {
                  label: 'gas limit',
                  value: `${order.txData && order.txData.gasLimit}`,
                },
                {
                  label: 'gas price',
                  value: `${order.gasPrice / 1e9} GWEI (${
                    order.gasPrice / 1e18
                  } ETH)`,
                },
                {
                  label: 'max tx fee',
                  value: `${fixDecimals(
                    (order.gasPrice * (order.txData && order.txData.gasLimit)) /
                      1e18,
                    4,
                  )} ETH (${
                    (order.gasPrice * (order.txData && order.txData.gasLimit)) /
                    1e9
                  } GWEI)`,
                },
              ]}
            />
          </ComponentWrapper>

          <ComponentWrapper>
            <SectionTitle>Important</SectionTitle>
          </ComponentWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, handleBlur }) => {
              const submitDisabled = !values.token

              return (
                <Form>
                  <ComponentWrapper noPaddingTop>
                    <AgreementItem
                      text={`By clicking ‘Sign transaction’ I agree with paying ${
                        project.denominationSymbol
                      } ${fixDecimals(
                        order.tokenPrice * order.tokenAmount,
                        project.euroDecimals,
                      )} for ${fixDecimals(
                        order.tokenAmount,
                        project.tokenRoundingDecimals,
                      )} ${project.tokenSymbol} Tokens.`}
                      checkbox={{
                        id: 'token',
                        name: 'token',
                        text: 'I agree',
                        value: 'agree',
                        onChange: handleChange,
                        onBlur: handleBlur,
                        checked: values.token,
                      }}
                    />
                  </ComponentWrapper>

                  {isConfirmOnMetaMask ? (
                    <ComponentWrapper borderTop borderBottom>
                      <CryptoAddressWrapper
                        refresh={
                          'Please proceed to MetaMask to finalise the transaction.'
                        }
                      >
                        <CryptoAddress
                          title="ethereum address"
                          address={''}
                          data={{
                            value: '',
                          }}
                        />
                      </CryptoAddressWrapper>
                    </ComponentWrapper>
                  ) : (
                    <ComponentWrapper noPaddingTop center>
                      <Button
                        type="submit"
                        minWidth="240px"
                        onClick={abortOnEtherRateChange}
                        secondary
                        style={{ margin: '10px' }}
                      >
                        Abort
                      </Button>
                      <Button
                        type="submit"
                        minWidth="240px"
                        disabled={submitDisabled}
                        onClick={onSubmit}
                        style={{ margin: '10px' }}
                      >
                        Confirm
                      </Button>
                    </ComponentWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </ComponentScroll>
      ) : (
        <ComponentScroll>
          <ComponentWrapper noPaddingVertical>
            <SignSubText>
              <h3>REVIEW & SIGN YOUR TRANSACTION</h3>
              <p>
                Please review your transaction and press the 'sign transaction'
                button. You'll then be asked to press 'confirm' in MetaMask.
              </p>
            </SignSubText>
          </ComponentWrapper>

          <ComponentWrapper noPaddingBottom={!isConfirmOnMetaMask}>
            <TransactionInfo
              value1={fixDecimals(order.currencyValue, user.currencyDecimals)}
              unit1={user.currencySymbol}
              name1={user.selectedAddress.name}
              owner1="(you)"
              address1={user.selectedAddress.address}
              value2={fixDecimals(
                order.tokenAmount,
                project.tokenRoundingDecimals,
              )}
              unit2={project.tokenSymbol}
              name2={project.tokenSymbol}
              owner2=""
              address2={user.selectedAddress.address}
              red
              redGreen
            />

            <TransactionDetails
              data={[
                {
                  label: 'token value in euro',
                  value: `${project.denominationSymbol} ${fixDecimals(
                    order.tokenPrice * order.tokenAmount,
                    project.euroDecimals,
                  )}`,
                  valueInfo: '(before investment)',
                },
                {
                  label: 'gas limit',
                  value: `${order.txData && order.txData.gasLimit}`,
                },
                {
                  label: 'gas price',
                  value: `${order.gasPrice / 1e9} GWEI (${
                    order.gasPrice / 1e18
                  } ETH)`,
                  labelwarning:
                    'Please do not change the gas price in your Metamask wallet so your transaction can pass smoothly.',
                },
                {
                  label: 'max tx fee',
                  value: `${fixDecimals(
                    (order.gasPrice * (order.txData && order.txData.gasLimit)) /
                      1e18,
                    4,
                  )} ETH (${
                    (order.gasPrice * (order.txData && order.txData.gasLimit)) /
                    1e9
                  } GWEI)`,
                },
              ]}
            />
          </ComponentWrapper>

          {isConfirmOnMetaMask ? (
            <ComponentWrapper borderTop borderBottom>
              <CryptoAddressWrapper
                refresh={
                  'Please proceed to MetaMask to finalise the transaction.'
                }
              >
                <CryptoAddress
                  title="ethereum address"
                  address={''}
                  data={{
                    value: '',
                  }}
                />
              </CryptoAddressWrapper>
            </ComponentWrapper>
          ) : (
            <>
              <ComponentWrapper>
                <SectionTitle>Important</SectionTitle>
              </ComponentWrapper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, handleBlur }) => {
                  const submitDisabled = !values.token

                  return (
                    <Form>
                      <ComponentWrapper noPaddingTop>
                        <AgreementItem
                          text={`By clicking ‘Sign transaction’ button I agree with paying ${
                            project.denominationSymbol
                          } ${fixDecimals(
                            order.tokenPrice * order.tokenAmount,
                            project.euroDecimals,
                          )} for ${fixDecimals(
                            order.tokenAmount,
                            project.tokenRoundingDecimals,
                          )} ${project.tokenSymbol} Tokens.`}
                          checkbox={{
                            id: 'token',
                            name: 'token',
                            text: 'I agree',
                            value: 'agree',
                            onChange: handleChange,
                            onBlur: handleBlur,
                            checked: values.token,
                          }}
                        />
                      </ComponentWrapper>

                      <ComponentWrapper noPaddingTop center>
                        <Button
                          type="submit"
                          minWidth="240px"
                          disabled={submitDisabled}
                        >
                          Sign transaction
                        </Button>
                      </ComponentWrapper>
                    </Form>
                  )
                }}
              </Formik>
            </>
          )}
        </ComponentScroll>
      )}
    </ModalWrapper>
  ) : (
    <StokrLoader />
  )
}

SignSubTransactionMetamask.propTypes = {
  changeStep: PropTypes.func.isRequired,
}
export default SignSubTransactionMetamask
