import { getCurrentProject } from 'utils/get-current-project'

const projectName = getCurrentProject()

export const INVEST = `/${projectName}`

export const SHOWIBAN = `${INVEST}/showiban`

export const PENDING_PAYMENT = `${INVEST}/pending-payment`

export const ORDER_TOKENS = `${INVEST}/order-tokens`

export const ALMOST_DONE = `${INVEST}/almost-done`

export const CONFIRM_INVESTMENT = `${INVEST}/confirm-investment`

export const SIGNING_SUBFLOW = `${INVEST}/signing-subflow`

export const SUCCESS = `${INVEST}/success`

export const OOPS = `${INVEST}/oops`

export const UNSUPPORTED = `${INVEST}/unsupported`

export const HARDWARE_MODALS = `${INVEST}/hardware-modals`

export const SALE_CLOSED = `${INVEST}/sale-closed`

export const TODO = `${INVEST}/todo`

export const FEEDBACK_VIEWS_AUTH = `${INVEST}/feedback-views/auth`

export const FEEDBACK_VIEWS_HARDWARE_SOFTWARE = `${INVEST}/feedback-views/hardware-software`

export const FEEDBACK_VIEWS_BEFORE_INVESTMENT = `${INVEST}/feedback-views/before-investment`

export const FEEDBACK_VIEWS_AFTER_INVESTMENT = `${INVEST}/feedback-views/after-investment`

export const LOGIN = `${INVEST}/login`

export const NOT_FOUND = `/not-found`
