import React, { useEffect, useState, useContext } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Background,
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  StokrLoader,
} from '@stokr/components-library'
// import { FAQ } from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import { ProjectContext } from 'context/Project/ProjectContext'
import { useLocation } from 'react-router'

const InvestingSigningSubflowUnsupportedPage = ({}) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  let { project } = useContext(ProjectContext)
  const location = useLocation()

  // Venture
  // const [venture, setVenture] = useState()

  useEffect(() => {
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 2,
        activeIndex: 3,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <Row main>
        <Column part={8}>
          <Background src={backgroundImage} mobileRatio="16:9" />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>Unsupported</h3>
              <p>
                {location && location.state && location.state.message
                  ? location.state.message
                  : 'Something went wrong, please start again.'}
              </p>
            </Text>
          </ComponentWrapper>
          <ComponentWrapper noPaddingTop>
            <Button fluid>
              <a
                href={`https://stokr.io/${project.name}`}
                style={{ fontWeight: 'inherit' }}
              >
                BACK TO INVESTMENT OPPORTUNITY
              </a>
            </Button>
          </ComponentWrapper>

          {/* <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title: 'What does it mean to ’sign’ a transaction?',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat.',
                },
                {
                  title: 'How is this transaction secured? ',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt venenatis sapien, quis efficitur augue tincidunt vel. Praesent vel laoreet est, a imperdiet erat.',
                },
              ]}
            />
          </ComponentWrapper> */}
        </Column>
      </Row>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default InvestingSigningSubflowUnsupportedPage
