import fixDecimals from 'utils/fix-decimals'

// export default async e => {
export const setBeforeAfterValues = async ({
  e,
  user,
  project,
  exchangeRates,
  setAfterAddressBalance,
  setAfterAddressBalanceinEURorUSD,
  setAfterAddressBalanceInToken,
  setAfterAddressBalanceInTokeninEURorUSD,
  setAfterWalletBalance,
  setAfterWalletBalanceinEURorUSD,
  setAfterWalletBalanceInToken,
  setAfterWalletBalanceInTokeninEURorUSD,
}) => {
  const inputCurrencyValue = e.target.currencyValue.toNumber
    ? e.target.currencyValue.toNumber()
    : e.target.currencyValue
  const inputTokenValue = e.target.value.toNumber
    ? e.target.value.toNumber()
    : parseFloat(e.target.value)

  if (project.isEthereum) {
    setAfterAddressBalanceInToken(
      fixDecimals(
        parseFloat(user.selectedAddress.tokenBalance) + inputTokenValue,
        project.tokenRoundingDecimals,
      ),
    )

    setAfterAddressBalance(
      fixDecimals(
        user.selectedAddress.currencyBalance - inputCurrencyValue,
        project.currencyDecimals,
      ),
    )

    setAfterAddressBalanceinEURorUSD(
      fixDecimals(
        (user.selectedAddress.currencyBalance - inputCurrencyValue) *
          (user.secondaryCurrency ? 1 : user.selectedCurrencyinEURorUSD),
        project.euroDecimals,
      ),
    )

    setAfterAddressBalanceInTokeninEURorUSD(
      fixDecimals(
        parseFloat(user.selectedAddress.tokenBalanceinEURorUSD) +
          inputCurrencyValue *
            (user.secondaryCurrency ? 1 : user.selectedCurrencyinEURorUSD),
        project.euroDecimals,
      ),
    )
  }

  setAfterWalletBalanceInToken(
    fixDecimals(
      parseFloat(user.selectedAddress?.balance) + inputTokenValue,
      project.tokenRoundingDecimals,
    ),
  )
  setAfterWalletBalance(
    e.target.isBMN1
      ? fixDecimals(
          user.selectedAddress?.currencyBalanceBMN1 - inputCurrencyValue,
          project.tokenRoundingDecimals,
        )
      : fixDecimals(
          user.selectedAddress?.balance - inputCurrencyValue,
          project.etherDecimals,
        ),
  )

  setAfterWalletBalanceinEURorUSD(
    e.target.isBMN1
      ? fixDecimals(
          user.selectedAddress?.currencyBalanceBMN1inBTC -
            inputCurrencyValue * exchangeRates.bmn1inBTC,
          8,
        )
      : fixDecimals(
          (user.selectedAddress?.tokenBalanceinEURorUSD - inputCurrencyValue) *
            (user.secondaryCurrency ? 1 : user.selectedCurrencyinEURorUSD),
          project.euroDecimals,
        ),
  )

  setAfterWalletBalanceInTokeninEURorUSD(
    fixDecimals(
      parseFloat(user.selectedAddress?.tokenBalanceinEURorUSD) +
        inputCurrencyValue *
          (user.secondaryCurrency ? 1 : user.selectedCurrencyinEURorUSD),
      project.euroDecimals,
    ),
  )
}

export default setBeforeAfterValues
