import axios from 'axios'
import { BASE_PUBLIC_URL } from 'utils/base-api-urls'

class ExchangeRates {
  static stokrApi = axios.create({ baseURL: BASE_PUBLIC_URL })

  static async getEtherPrice() {
    return (await this.stokrApi.post('/investment/etherprice')).data
  }

  static async getBTCinUSD() {
    return (await this.stokrApi.post('/investment/btcprice')).data
  }

  static async getExchangeRates() {
    return (await this.stokrApi.post('/investment/exchangerates')).data
  }
}

export default ExchangeRates
