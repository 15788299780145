import styled from 'styled-components/macro'
import { Button } from '@stokr/components-library'

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  ${Button} {
    min-width: 150px;
    max-width: 300px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    ${Button} {
      min-width: 150px;
      max-width: 400px;
    }
  }
`
