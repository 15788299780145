import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils/breakpoint'
import { CSSTransition } from 'react-transition-group'
import { X } from 'components/icons'
import playButton from './img/play-btn.svg'

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  ${({ videoStyle }) => videoStyle}

  ${({ isInvestingExpanded }) =>
    isInvestingExpanded &&
    css`
      align-items: center;
      display: flex;
    `}
`

const zIndex = 9999

const LightboxVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${zIndex};

  &::before {
    display: block;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    opacity: 0.8;
    z-index: ${zIndex - 1};
    transform-origin: left center;
  }
  &.lightbox-enter::before {
    transform: scaleX(0.01);
    transition: all ${(props) => props.lightboxAnimationLen}ms
      cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &.lightbox-enter-active::before {
    transform: scaleX(1);
  }
  &.lightbox-exit::before {
    transform: scaleX(1);
    transform-origin: right center;
  }
  &.lightbox-exit-active::before {
    transform: scaleX(0);
    transition: all ${(props) => props.lightboxAnimationLen / 2}ms
      cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: ${(props) => props.videoAnimationLen / 2}ms;
  }

  video,
  iframe {
    max-width: 80vw;
    max-height: 90vh;
    position: relative;
    z-index: ${zIndex + 1};
    box-shadow: 0 0 20px 1px black;
    transition: all 0.4s;

    ${breakpoint.below('tablet')} {
      max-width: 88vw;
    }

    @media screen and (max-width: 768px) {
      max-width: 95vw;
    }
  }

  iframe {
    ${breakpoint.below('mobile')} {
      height: 300px;
    }
  }
  &.lightbox-enter video,
  &.lightbox-enter iframe {
    transform: scale(0.8) translate3d(-12%, 0, 0);
    opacity: 0.01;
    transition: all ${(props) => props.videoAnimationLen}ms
      cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: ${(props) => props.lightboxAnimationLen * 0.75}ms;
  }
  &.lightbox-enter-active video,
  &.lightbox-enter-active iframe {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
  &.lightbox-exit video,
  &.lightbox-exit iframe {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
  &.lightbox-exit-active video,
  &.lightbox-exit-active iframe {
    transform: scale(0.8) translate3d(13%, 0, 0);
    opacity: 0;
    transition: all ${(props) => props.videoAnimationLen * 0.75}ms
      cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`

export const PlayButton = styled.div`
  position: absolute;
  height: 100%;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ isSmallCard }) => (isSmallCard ? 'flex-start' : 'center')};
  z-index: 10;

  ${({ isCenter }) =>
    isCenter &&
    css`
      height: calc(100% - 48px);
    `}

  ${({ isInvestingExpanded }) =>
    isInvestingExpanded &&
    css`
      bottom: unset;
    `}

  img {
    transition: transform 0.3s;
    position: relative;
    top: ${({ isSmallCard }) => (isSmallCard ? '100px' : '0px')};

    @media screen and (min-width: 769px) {
      top: ${({ isSmallCard }) => (isSmallCard ? '132px' : '0px')};
    }
  }

  &:hover,
  &:focus,
  &:active {
    img {
      transform: scale(1.25);
    }
  }
`

const CloseButtonContainer = styled.div`
  align-items: center;
  background: ${sc.BLUE_BASE_HEX};
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  transition: all 0.2s;
  z-index: 99999;

  &:hover {
    background: transparent;

    svg {
      transform: rotate(-180deg);
      fill: #000;
    }
  }

  svg {
    fill: #fff;
    transition: all 0.2s;
  }
`

export const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
  @media screen and (max-width: 480px) {
    height: 250px;
  }
`

export class Video extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    autoplay: PropTypes.bool, // Hack to let the component know the default attribute
    poster: PropTypes.any,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  }

  lightboxAnimationLen = 550

  videoAnimationLen = 440

  animationLen = this.lightboxAnimationLen + this.videoAnimationLen

  state = {
    isPlaying: this.props.autoplay,
  }

  componentDidUpdate(prevProps) {
    const { play } = this.props

    if (prevProps.play !== play) {
      play ? this.togglePlay() : this.toggleStop()
    }
  }

  togglePlay = () => {
    const { onOpen } = this.props
    const isPlaying = true

    onOpen && onOpen()
    this.setState({ isPlaying })

    document.body.style.overflow = 'hidden'
  }

  toggleStop = () => {
    const { onClose } = this.props
    const isPlaying = false

    onClose && onClose()
    this.setState({ isPlaying })

    document.body.style.overflow = 'unset'
  }

  render() {
    const {
      src,
      poster,
      videoStyle,
      isCenter,
      isSmallCard,
      isInvestingExpanded,
      noPlayButton,
      ...otherProps
    } = this.props
    const { isPlaying } = this.state

    delete otherProps.onOpen
    delete otherProps.onClose
    let videoId

    if (src?.includes('youtube')) {
      videoId = YouTubeGetID(src)
    }
    return (
      <Wrapper
        onClick={!noPlayButton ? this.togglePlay : () => {}}
        videoStyle={videoStyle}
        isInvestingExpanded={isInvestingExpanded}
      >
        {poster && poster}
        {!noPlayButton && (
          <PlayButton
            isCenter={isCenter}
            isSmallCard={isSmallCard}
            isInvestingExpanded={isInvestingExpanded}
          >
            <img alt="Play video" src={playButton} />
          </PlayButton>
        )}

        <CSSTransition
          in={isPlaying}
          timeout={this.animationLen}
          classNames="lightbox"
          unmountOnExit
        >
          <LightboxVideo
            isPlaying={isPlaying}
            onClick={this.toggleStop}
            lightboxAnimationLen={this.lightboxAnimationLen}
            videoAnimationLen={this.videoAnimationLen}
          >
            <CloseButtonContainer>
              <X />
            </CloseButtonContainer>

            {videoId ? (
              <iframe
                width="853"
                height="510"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            ) : (
              <video
                ref={(video) => video && video.play()}
                controls
                src={src}
                {...otherProps}
              />
            )}
            {/* -line */}
          </LightboxVideo>
        </CSSTransition>
      </Wrapper>
    )
  }
}

function YouTubeGetID(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

export default Video
