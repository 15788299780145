import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { Portal } from 'react-portal'

import {
  ModalRoot,
  Dimmer,
  Box,
  ModalInner,
  ModalClose,
  ModalBack,
  ModalLinkWrap,
  ModalLink,
  ModalInnerWithBorder,
  ModalInnerSmartContractAddress,
} from './Modal.styles'

const timeout = 300
const getTransitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}

const Modal = ({
  children,
  fullscreen,
  kyc,
  thin,
  isOpen,
  onClose,
  className,
  background,
}) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'unset'
    }
  }, [])

  return (
    <Portal isOpened={isOpen}>
      <Transition
        in={isOpen}
        appear
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {status => (
          <ModalRoot className={className} style={getTransitionStyles[status]}>
            <Dimmer
              open={isOpen}
              background={background}
              kyc={kyc}
              closeOnDimmerClick={false}
              onClose={onClose}
            >
              <Box kyc={kyc} fullscreen={fullscreen} thin={thin}>
                <ModalClose onClick={onClose} />
                {children}
              </Box>
            </Dimmer>
          </ModalRoot>
        )}
      </Transition>
    </Portal>
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  kyc: PropTypes.bool,
  thin: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  className: '',
  fullscreen: false,
  kyc: false,
  thin: false,
}

export {
  Modal,
  ModalInner,
  ModalBack,
  ModalClose,
  ModalLinkWrap,
  ModalLink,
  ModalInnerWithBorder,
  ModalInnerSmartContractAddress,
}
