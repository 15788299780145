import styled, { css } from 'styled-components/macro'
import grid from 'styles/grid'
import rwd from 'styles/rwd'
import Text from 'components/Text/Text.styles'

export const SignSubStep = styled.div`
  position: relative;
  height: 100%;
`

export const SignSubWrapper = styled.div`
  position: relative;
  padding: 40px ${grid(1)};

  ${rwd.Medium`
    padding: 53px ${grid(0.5, 8)};
  `}

  ${rwd.XLarge`
    padding: 53px ${grid(0.5, 6)};
  `}
`

export const SignSubInfo = styled.div`
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 9px;
  line-height: 1.78em;
  letter-spacing: 0.4px;
  color: ${props => props.theme.cBlack};
  opacity: 0.5;
  ${props => props.center && 'text-align: center;'}

  ${rwd.Large`
    font-size: 12px;
  `}

  a {
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: ${props => props.theme.cPrimary};
    }
  }
`

export const NumberWithTitle = styled.div`
  line-height: 24px;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 18px;
`

export const SignSubText = styled(Text)`
  p {
    ${rwd.Medium`
      font-size: 16px;
    `}
  }

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.cWarning};

      h4 {
        font-weight: 600;
        opacity: 1;
      }
    `}
`
