// import U2F from '@ledgerhq/hw-transport-u2f'
// import Ledger from '@ledgerhq/hw-app-eth'
import Config from 'utils/StokrConfig'

// Metamask Section

export const getMetamaskAddress = async () => {
  try {
    let mmaddress = await window.ethereum.enable()
    return mmaddress
  } catch (error) {
    return error
  }
}

// Ledger

const getLedgerHandle = async () => {
  // const transport = await U2F.create(
  //   Config.ledger.openTimeout,
  //   Config.ledger.listenTimeout,
  // )
  // return new Ledger(transport)
}

export const isLedgerAvailable = async () => {
  const ledger = await getLedgerHandle()
  const path = `/m/44'/60'/0'/0/0`

  try {
    await ledger.getAddress(path, false, false)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

let pool
let counter

const getSingleAddressData = async (ledger, path, id) => {
  const single = await ledger.getAddress(path, false, false)

  const data = {
    address: single.address,
    path: path,
    title: `Ethereum ${id}`,
    id: `address${id}`,
    radioId: `eth ${id}`,
  }

  return data
}

export const getLedgerAddresses = async (depth = Config.ledger.scanDepth) => {
  pool = []
  counter = 0

  const ledger = await getLedgerHandle()

  try {
    for (let i = 0; i < depth; i++) {
      const keyDerivationPath = `/m/44'/60'/0'/${i}/0`
      const legacyKeyDerivationPath = `/m/44'/60'/0'/${i}`

      pool.push(await ledger.getAddress(keyDerivationPath, false, false))
      pool.push(await ledger.getAddress(legacyKeyDerivationPath, false, false))
    }

    return pool
  } catch (error) {
    console.error(error.name)
    console.error(error)
  }
}

// expects address, returns key derivation path, if found
export const getKeyDerivationPathForAddress = async (
  address,
  depth = Config.ledger.scanDepth,
) => {
  const ledger = await getLedgerHandle()

  try {
    for (let i = 0; i < depth; i++) {
      const keyDerivationPath = `/m/44'/60'/0'/${i}/0`
      const legacyKeyDerivationPath = `/m/44'/60'/0'/${i}`

      const result = await getSingleAddressData(
        ledger,
        keyDerivationPath,
        ++counter,
      )
      if (result.address === address) return keyDerivationPath

      const legacyResult = await getSingleAddressData(
        ledger,
        legacyKeyDerivationPath,
        ++counter,
      )
      if (legacyResult.address === address) return legacyKeyDerivationPath
    }

    return null
  } catch (error) {
    console.error(error.name)
    console.error(error)
  }
}
