import { ProjectTypes } from 'constants/enums'

const getTimeLeft = (endTime) => {
  const now = new Date()
  const oneDay = 1000 * 60 * 60 * 24

  const timeLeft = (endTime - now) / oneDay

  if (timeLeft >= 1) {
    // Equal or more than 24h
    return {
      time: Math.round(timeLeft),
      text: 'DAYS',
    }
  } else if (timeLeft * 24 >= 1) {
    // Less than 24h
    return {
      time: Math.round(timeLeft * 24),
      text: 'HOURS',
    }
  } else {
    return {
      time: Math.round(timeLeft * 24 * 60),
      text: 'MINS',
    }
  }
}

export const checkSaleTimeLeft = (project) => {
  const { openingTime, closingTime, isOpen, hasClosed, type, sales } = project

  let timeLeftData = {
    openingTime,
    closingTime,
    isOpen,
    hasClosed,
  }

  //for the tranche project we check the current tranche status
  if (type === ProjectTypes.TRANCHES) {
    const currentTranche = sales?.find((x) => x.isVariable)
    const today = Date.now()

    if (currentTranche) {
      //if we have variable tranche we set opening and closing date of tranche
      timeLeftData.openingTime = currentTranche.startDate
      timeLeftData.closingTime = currentTranche.endDate

      if (today < new Date(currentTranche.endDate)) {
        timeLeftData.isOpen = true
        timeLeftData.hasClosed = false
      } else if (today > new Date(currentTranche.endDate)) {
        timeLeftData.hasClosed = true
        timeLeftData.isOpen = false
      } else {
        timeLeftData.isOpen = false
        timeLeftData.hasClosed = false
      }
    } else {
      //upcoming state where we don't have variable sale
      //we use opening and closing time of project
      timeLeftData = {
        ...timeLeftData,
        isOpen: false,
        hasClosed: false,
      }
    }
  }
  return calculateTimeLeft(timeLeftData)
}

const calculateTimeLeft = (timeLeftData) => {
  const { openingTime, closingTime, isOpen, hasClosed } = timeLeftData

  if (!isOpen && !hasClosed) {
    // Before sale
    const startingDate = new Date(openingTime)

    const timeLeft = getTimeLeft(startingDate)

    return {
      daysLeft: timeLeft.time,
      daysLeftText: `${timeLeft.text} UNTIL START`,
    }
  } else if (isOpen) {
    // During sale
    const endingDate = new Date(closingTime)

    const timeLeft = getTimeLeft(endingDate)

    return {
      daysLeft: timeLeft.time,
      daysLeftText: `${timeLeft.text} LEFT`,
    }
  } else {
    // After sale
    return { daysLeftText: 'FUNDING CLOSED' }
  }
}

export default checkSaleTimeLeft
