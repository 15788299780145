import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  display: block;

  input:-webkit-autofill {
    border: none;
    border-bottom: 1px solid #e1e1e1;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ${(props) =>
    props.inverted &&
    css`
      ${Label} {
        color: ${props.theme.cWhite};
        opacity: 0.4;
      }
      ${InputWrap} {
        & > input {
          color: ${props.theme.cWhite};
          border-bottom-color: ${props.theme.cWhite};

          &:focus,
          &:hover {
            border-bottom-color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    `}
`

export const Label = styled.label`
  z-index: 11;
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  height: 12px;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${(props) => props.theme.cBlack};
  pointer-events: none;
  transform: translateY(15px);
  transition: transform 0.2s, font-size 0.2s;

  ${(props) =>
    props.isUp &&
    `
    font-size: 10px;
    transform: translateY(0);
  `}

  ${(props) =>
    props.active &&
    `
    color: ${props.theme.cPrimary};
  `}

  ${(props) =>
    props.error &&
    `
    color: ${props.theme.cWarning};
  `}
`

export const InputWrap = styled.div`
  position: relative;

  & > input {
    z-index: 0;
    font-family: 'Open sans';
    display: block;
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 15px 0;
    padding-bottom: 5px;
    box-shadow: none;
    border: 0;
    outline: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.cGrey};
    color: ${(props) => props.theme.cBlack};
    background-color: transparent;
    transition: border-color 0.2s;

    &:focus,
    &:hover {
      border-top-color: transparent;
      border-bottom-color: ${(props) => props.theme.cPrimary};
    }

    &::placeholder {
      color: transparent;
      opacity: 0;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      & > input {
        color: ${(props) => props.theme.cGrey2};
        &:focus,
        &:hover {
          border-top-color: transparent;
          border-bottom-color: ${(props) => props.theme.cGrey};
        }
      }
    `}

  ${(props) =>
    props.dashboard &&
    css`
      & > input {
        border: 0;
        padding: 0;
        height: 22px;
        font-family: 'Open Sans';
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.53px;
        text-transform: normal;
        color: ${props.theme.cBlack};

        &::placeholder {
          color: ${props.theme.cGrey2};
          opacity: 1;
        }
      }
    `}
`
