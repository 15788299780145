import axios from 'axios'
import { BASE_PUBLIC_URL, BASE_URL } from 'utils/base-api-urls'
import getAuthHeader from 'utils/getAuthHeader'

class Project {
  static async getProject(projectName) {
    const response = await axios.post(`${BASE_PUBLIC_URL}/project/public/get`, {
      projectNamesList: [projectName],
    })
    return response.data.projects[0]
  }

  static getBlocks(projectId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_PUBLIC_URL}/block/public/getByType`, {
          blockType: projectId,
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static getDocuments(projectId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/documents/list`,
          {
            projectId: projectId,
            ignoreInvestmentDocs: true,
          },
          { headers: getAuthHeader() },
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static getCompany(companyId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_PUBLIC_URL}/company/public/get`, {
          companyId,
        })
        .then((response) => {
          resolve(response.data.company)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default Project
