import React from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  Row,
  Label,
  Value,
  LabelWarning,
  Caption,
} from './BasicTable.styles'

const BasicTable = ({ data, filled, smallPadding }) => (
  <Table filled={filled}>
    {data.map((item, itemKey) => (
      <Row key={item.label} smallPadding={smallPadding}>
        <Label>{item.label}</Label>
        <Value>
          {item.value}
          <Caption>{item.valueDescription}</Caption>
        </Value>
        {item.labelwarning ? (
          <LabelWarning>{item.labelwarning}</LabelWarning>
        ) : (
          ''
        )}
      </Row>
    ))}
  </Table>
)

BasicTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BasicTable
