import axios from 'axios'
import { BASE_PUBLIC_URL } from 'utils/base-api-urls'

export default async (subject, body, to) => {
  try {
    await axios({
      method: 'POST',
      url: `${BASE_PUBLIC_URL}/user/sendEmailToAdmin`,
      data: {
        subject,
        body,
        to,
      },
    })
  } catch (error) {
    throw error
  }
}
