import React from 'react'
import SelectAddressPage from 'pages/select-address'
import OrderTokensPage from 'pages/order-tokens'
import AlmostDonePage from 'pages/almost-done'
import InvestSigningSubflowPage from 'pages/signing-subflow'
import InvestingSigningSubflowUnsupportedPage from 'pages/unsupported'
import ShowIbanPage from 'pages/showiban'
import PendingPaymentPage from 'pages/pending-payment'
import InvestSuccessPage from 'pages/success'
import SaleClosed from 'pages/sale-closed'
import InvestingOopsPage from 'pages/oops'
import NotFoundPage from 'pages/404'
import { LoginWithOTPFlow } from '@stokr/components-library'

import * as urls from 'constants/paths'

const InvestSTORoutes = [
  {
    path: urls.INVEST,
    element: <SelectAddressPage />,
  },
  {
    path: urls.ORDER_TOKENS,
    element: <OrderTokensPage />,
  },
  {
    path: urls.ALMOST_DONE,
    element: <AlmostDonePage />,
  },
  {
    path: urls.SHOWIBAN,
    element: <ShowIbanPage />,
  },
  {
    path: urls.PENDING_PAYMENT,
    element: <PendingPaymentPage />,
  },
  {
    path: urls.SIGNING_SUBFLOW,
    element: <InvestSigningSubflowPage />,
  },
  {
    path: urls.SUCCESS,
    element: <InvestSuccessPage />,
  },
  {
    path: urls.OOPS,
    element: <InvestingOopsPage />,
  },
  {
    path: urls.UNSUPPORTED,
    element: <InvestingSigningSubflowUnsupportedPage />,
  },
  {
    path: urls.SALE_CLOSED,
    element: <SaleClosed />,
  },
  {
    path: urls.LOGIN,
    element: <LoginWithOTPFlow />,
  },
  {
    path: urls.NOT_FOUND,
    element: <NotFoundPage />,
  },
]

export default InvestSTORoutes
