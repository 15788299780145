import React, { useEffect, useState, useContext, useRef } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  BackButton,
  Button,
  Row,
  Column,
  SectionTitle,
  ComponentWrapper,
  Switch,
  StokrLoader,
  CryptoAddress,
} from '@stokr/components-library'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import { Formik } from 'formik'
import Form from 'components/Form/Form'
import InvestCalculator from 'components/InvestCalculator/InvestCalculator'
import { SwitchInTitleWrapper } from 'styles/Pages/common.styles'
import fixDecimals from 'utils/fix-decimals'
import setBeforeAfterValues from 'utils/set-before-after-values'
import * as urls from 'constants/paths'
import { OrderContext } from 'context/Order/OrderContext'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'

const OrderTokensPage = ({ location }) => {
  let { exchangeRates } = useContext(ExchangeRatesContext)
  let { project } = useContext(ProjectContext)
  let { user, setAfterValues, setSelectedAddress } = useContext(UserContext)
  let { updateOrder } = useContext(OrderContext)
  const navigator = useCustomNav()

  const [errors, setErrors] = useState({})
  const [shouldRenderPage, setShouldRenderPage] = useState(false)

  // Before-After switch
  const [isAfterSelected, setIsAfterSelected] = useState(false)

  // Address
  const [afterAddressBalance, setAfterAddressBalance] = useState(
    user.selectedAddress?.currencyBalance,
  )
  const [afterAddressBalanceinEURorUSD, setAfterAddressBalanceinEURorUSD] =
    useState(user.selectedAddress?.currencyBalanceinEURorUSD)
  const [afterAddressBalanceInToken, setAfterAddressBalanceInToken] = useState(
    user.selectedAddress?.tokenBalance,
  )
  const [
    afterAddressBalanceInTokeninEURorUSD,
    setAfterAddressBalanceInTokeninEURorUSD,
  ] = useState(user.selectedAddress?.tokenBalanceinEURorUSD)

  // Wallet
  const [afterWalletBalance, setAfterWalletBalance] = useState(
    user.selectedAddress?.balance,
  )
  const [afterWalletBalanceinEURorUSD, setAfterWalletBalanceinEURorUSD] =
    useState(user.selectedAddress?.tokenBalanceinEURorUSD)
  const [afterWalletBalanceInToken, setAfterWalletBalanceInToken] = useState(
    user.selectedAddress?.balance,
  )
  const [
    afterWalletBalanceInTokeninEURorUSD,
    setAfterWalletBalanceInTokeninEURorUSD,
  ] = useState(user.selectedAddress?.tokenBalanceinEURorUSD)

  const [tokenAmount, setTokenAmount] = useState(0)
  const [currencyValue, setCurrencyValue] = useState(0)

  const feePercentageRef = useRef(null)
  const feeInEURorUSDRef = useRef(null)

  useEffect(() => {
    if (!user.selectedAddress) {
      navigator.navigate(`/${project.name}`)
    }
  }, [])

  useEffect(() => {
    setShouldRenderPage(true)
  }, [user])

  const updateAfterValues = async (e) => {
    if (project.isEthereum || project.isAlgorand) {
      setBeforeAfterValues({
        e,
        user,
        project,
        exchangeRates,
        setAfterAddressBalance,
        setAfterAddressBalanceinEURorUSD,
        setAfterAddressBalanceInToken,
        setAfterAddressBalanceInTokeninEURorUSD,
        setAfterWalletBalance,
        setAfterWalletBalanceinEURorUSD,
        setAfterWalletBalanceInToken,
        setAfterWalletBalanceInTokeninEURorUSD,
      })
    } else {
      setBeforeAfterValues({
        e,
        user,
        project,
        exchangeRates,
        setAfterWalletBalance,
        setAfterWalletBalanceinEURorUSD,
        setAfterWalletBalanceInToken,
        setAfterWalletBalanceInTokeninEURorUSD,
      })
    }
  }

  const initalizeSetAfterValues = () => {
    setAfterValues({
      addressCurrencyBalance: afterAddressBalance,
      addressCurrencyBalanceinEURorUSD: afterAddressBalanceinEURorUSD,
      addressTokenBalance: afterAddressBalanceInToken,
      addressTokenBalanceinEURorUSD: afterAddressBalanceInTokeninEURorUSD,
      totalCurrencyBalance: afterWalletBalance,
      totalCurrencyBalanceinEURorUSD: afterWalletBalanceinEURorUSD,
      totalTokenBalance: afterWalletBalanceInToken,
      totalTokenBalanceinEURorUSD: afterWalletBalanceInTokeninEURorUSD,
    })
  }

  const handleBeforeAfter = (e) => {
    if (e === 'AFTER') {
      setIsAfterSelected(true)
    } else if (e === 'BEFORE') {
      setIsAfterSelected(false)
    }
  }

  //we clear the selected address before we Navigate to select address page
  //if not -- select address page will try to Navigate again(until it updates the state)
  //causing bad user experience
  const clearSelectedAddress = () => {
    setSelectedAddress()
  }
  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 0,
        activeIndex: 1,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <Row main>
        <Column part={8}>
          <ComponentWrapper onClick={clearSelectedAddress}>
            <BackButton to={urls.INVEST}>BACK</BackButton>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <InfoPanel />
          </ComponentWrapper>

          <ComponentWrapper borderBottom>
            <SectionTitle>
              {project.isEthereum
                ? 'MY WALLET'
                : project.isLiquid
                ? 'YOUR REGISTERED LIQUID ADDRESS'
                : project.isAlgorand
                ? 'MY ALGORAND WALLET'
                : ''}
              <SwitchInTitleWrapper right>
                <Switch
                  value="BEFORE"
                  value1="BEFORE"
                  value2="AFTER"
                  handleChange={(e) => {
                    handleBeforeAfter(e)
                  }}
                />
              </SwitchInTitleWrapper>
            </SectionTitle>
          </ComponentWrapper>

          {project.isAlgorand ? (
            <>
              <ComponentWrapper noPadding borderBottom>
                <Row>
                  <Column>
                    <CryptoAddress
                      eqValueFontSize={13}
                      title={user.selectedAddress?.name}
                      data={{
                        value: user.selectedAddress?.address,
                        shortAddress: true,
                        tooltip: true,
                      }}
                      info={{
                        value: `${project.tokenSymbol} ${
                          isAfterSelected
                            ? fixDecimals(
                                afterWalletBalanceInToken,
                                project.tokenRoundingDecimals,
                              )
                            : fixDecimals(
                                user.totalTokenBalance,
                                project.tokenRoundingDecimals,
                              )
                        }`,
                        eqValue: isAfterSelected
                          ? fixDecimals(
                              afterWalletBalanceInTokeninEURorUSD,
                              project.euroDecimals,
                            )
                          : fixDecimals(
                              user.totalTokenBalanceinEURorUSD,
                              project.euroDecimals,
                            ),
                        eqUnit: project.denominationSymbol,
                      }}
                      wrapped
                    />
                  </Column>
                </Row>
              </ComponentWrapper>
            </>
          ) : project.isEthereum ? (
            <>
              <ComponentWrapper noPadding borderBottom>
                <Row>
                  <Column>
                    <CryptoAddress
                      eqValueFontSize={13}
                      title={user.currencyName}
                      data={{
                        value:
                          isAfterSelected && !user.isIBAN
                            ? fixDecimals(
                                afterWalletBalance,
                                user.currencyDecimals,
                              )
                            : fixDecimals(
                                user.totalCurrencyBalance,
                                user.currencyDecimals,
                              ),
                        unit:
                          user.currencyInternalReference === 'euro'
                            ? 'ETH'
                            : user.currencySymbol,
                        eqValue: isAfterSelected
                          ? fixDecimals(
                              afterWalletBalanceinEURorUSD,
                              project.euroDecimals,
                            )
                          : fixDecimals(
                              user.totalCurrencyBalanceinEURorUSD,
                              project.euroDecimals,
                            ),
                        eqUnit: project.denominationSymbol,
                      }}
                      info={{
                        value: `${project.tokenSymbol} ${
                          isAfterSelected
                            ? fixDecimals(
                                afterWalletBalanceInToken,
                                project.tokenRoundingDecimals,
                              )
                            : fixDecimals(
                                user.totalTokenBalance,
                                project.tokenRoundingDecimals,
                              )
                        }`,
                        eqValue: isAfterSelected
                          ? fixDecimals(
                              afterWalletBalanceInTokeninEURorUSD,
                              project.euroDecimals,
                            )
                          : fixDecimals(
                              user.totalTokenBalanceinEURorUSD,
                              project.euroDecimals,
                            ),
                        eqUnit: project.denominationSymbol,
                      }}
                      wrapped
                    />
                  </Column>
                </Row>
              </ComponentWrapper>

              <ComponentWrapper borderBottom>
                <SectionTitle>MY SELECTED ADDRESS</SectionTitle>
              </ComponentWrapper>

              <Formik
                initialValues={{
                  userAddresses: user.investor_eth_addresses,
                }}
              >
                {() => {
                  return (
                    <Form>
                      <CryptoAddress
                        eqValueFontSize={13}
                        title={user.selectedAddress?.name}
                        address={user.selectedAddress?.address}
                        status={
                          user.selectedAddress?.isWhitelisted ? '' : 'pending'
                        }
                        data={{
                          value: isAfterSelected
                            ? fixDecimals(
                                afterAddressBalance,
                                user.currencyDecimals,
                              )
                            : fixDecimals(
                                user.selectedAddress?.currencyBalance,
                                user.currencyDecimals,
                              ),
                          unit:
                            user.currencyInternalReference === 'euro'
                              ? 'ETH'
                              : user.currencySymbol,
                          eqValue: isAfterSelected
                            ? fixDecimals(
                                afterAddressBalanceinEURorUSD,
                                project.euroDecimals,
                              )
                            : fixDecimals(
                                user.selectedAddress?.currencyBalanceinEURorUSD,
                                project.euroDecimals,
                              ),
                          eqUnit: project.denominationSymbol,
                        }}
                        info={{
                          value: `${project.tokenSymbol} ${
                            isAfterSelected
                              ? fixDecimals(
                                  afterAddressBalanceInToken,
                                  project.tokenRoundingDecimals,
                                )
                              : fixDecimals(
                                  user.selectedAddress?.tokenBalance,
                                  project.tokenRoundingDecimals,
                                )
                          }`,
                          eqValue: isAfterSelected
                            ? fixDecimals(
                                afterWalletBalanceInTokeninEURorUSD,
                                project.euroDecimals,
                              )
                            : fixDecimals(
                                user.selectedAddress?.tokenBalanceinEURorUSD,
                                project.euroDecimals,
                              ),
                          eqUnit: project.denominationSymbol,
                        }}
                        radio={{
                          id: 'investorAddress',
                          name: 'investorAddress',
                          value: 'eth 02', // account.address,
                          onChange: () => {},
                          onBlur: () => {},
                          checked: true,
                          hide: true,
                          disabled: true,
                        }}
                        wrapped
                        borderBottom
                      />
                    </Form>
                  )
                }}
              </Formik>
            </>
          ) : project.isLiquid ? (
            <CryptoAddress
              eqValueFontSize={13}
              title={''}
              status={''}
              data={
                user.isBMN1
                  ? {
                      value: isAfterSelected
                        ? fixDecimals(afterWalletBalance, user.currencyDecimals)
                        : fixDecimals(
                            user.selectedAddress.currencyBalanceBMN1,
                            user.currencyDecimals,
                          ),
                      unit: user.currencySymbol,
                      eqValue: isAfterSelected
                        ? fixDecimals(
                            afterWalletBalanceinEURorUSD, //btc balance after
                            8,
                          )
                        : fixDecimals(
                            user.selectedAddress.currencyBalanceBMN1inBTC,
                            8,
                          ),
                      eqUnit: 'BTC',
                    }
                  : {
                      value: user.selectedAddress?.address,
                      shortAddress: true,
                      tooltip: true,
                    }
              }
              info={{
                value: `${project.tokenSymbol} ${
                  isAfterSelected
                    ? fixDecimals(
                        afterWalletBalanceInToken,
                        project.tokenRoundingDecimals,
                      )
                    : fixDecimals(
                        user.selectedAddress?.balance,
                        project.tokenRoundingDecimals,
                      )
                }`,
                eqValue: isAfterSelected
                  ? fixDecimals(
                      afterWalletBalanceInTokeninEURorUSD,
                      project.euroDecimals,
                    )
                  : fixDecimals(
                      user.selectedAddress?.tokenBalanceinEURorUSD,
                      project.euroDecimals,
                    ),
                eqUnit: `= ${project.denominationSymbol}`,
                noEqualsToSign: 'true',
              }}
              wrapped
              borderBottom
            />
          ) : (
            <></>
          )}

          <ComponentWrapper grow />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>SELECT AMOUNT YOU'D LIKE TO INVEST</h3>
              <p>
                {`Type here the amount of ${project.tokenSymbol} securities you would like to purchase.`}
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <Formik
              initialValues={{
                tokens: '',
                currencyAmount: '0',
              }}
              onSubmit={(e) => {
                updateOrder({
                  tokenAmount,
                  currencyValue,
                  feePercentage: feePercentageRef.current,
                  feeInEURorUSD: feeInEURorUSDRef.current,
                })
                initalizeSetAfterValues()

                navigator.navigate(urls.ALMOST_DONE, {
                  state: { e, isAfterSelected },
                })
              }}
              validate={() => {}}
            >
              {({
                values,
                touched,
                handleBlur,
                setFieldValue,
                setFieldTouched,
              }) => {
                const onChangeWithTouch = (e) => {
                  setTokenAmount(e.target.value)
                  setCurrencyValue(e.target.selectedCurrencyValue)
                  feeInEURorUSDRef.current = e.target.feeInEURorUSD
                  feePercentageRef.current = e.target.feePercentage

                  updateAfterValues(e)

                  const field = e.target
                  setFieldTouched(field.name, true, false)
                  setFieldValue(field.name, field.value, false)
                  setFieldValue('currencyAmount', field.currencyValue, false)
                }

                const submitDisabled =
                  !touched.tokens ||
                  Object.keys(errors).length > 0 ||
                  parseFloat(values.currencyAmount) === 0

                return (
                  <Form>
                    <ComponentWrapper noPaddingTop>
                      <InvestCalculator
                        id="tokens"
                        value={values.tokens}
                        onChange={onChangeWithTouch}
                        onBlur={handleBlur}
                        onErrors={setErrors}
                      />
                    </ComponentWrapper>

                    <ComponentWrapper noPaddingTop>
                      <Button type="submit" disabled={submitDisabled}>
                        Continue
                      </Button>
                    </ComponentWrapper>
                  </Form>
                )
              }}
            </Formik>
          </ComponentWrapper>
        </Column>
      </Row>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default OrderTokensPage
