import React from 'react'
import NotFoundPage from 'pages/404'
import InvestSTORoutes from './InvestSTO'

const routes = [
  ...InvestSTORoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routes
