import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  StokrLoader,
  LoginWithOTPFlow,
  AuthConsumer,
  AuthProvider,
} from '@stokr/components-library'
import routes from './routes/index'
import { UserProvider, UserConsumer } from 'context/User/UserContext'
import {
  ProjectProvider,
  ProjectConsumer,
} from 'context/Project/ProjectContext'
import {
  ExchangeRatesProvider,
  ExchangeRatesConsumer,
} from 'context/ExchangeRates/ExchangeRatesContext'
import { OrderProvider, OrderConsumer } from 'context/Order/OrderContext'
import CheckboxProvider from 'context/Checkbox/CheckboxContext'
import axios from 'axios'
import Cookies from 'js-cookie'

const App = () => {
  const customValidateGetUser = async (user) => {
    if (user.user_type !== 'investor' && user.user_type !== 'investor_entity') {
      window.location.href = `https://admin.${process.env.REACT_APP_WEBSITE_DOMAIN}`
    }
    const accessToken = Cookies.get('STOKR_ACCESS_TOKEN')
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }
  return (
    <BrowserRouter>
      <AuthProvider customValidateGetUser={customValidateGetUser}>
        <AuthConsumer>
          {({ user, isFetchingUser }) => (
            <ProjectProvider authUser={user}>
              <ProjectConsumer>
                {({ isFetchingProject, project }) => (
                  <ExchangeRatesProvider project={project}>
                    <ExchangeRatesConsumer>
                      {user
                        ? ({ isFetchingExchangeRates, exchangeRates }) => (
                            <UserProvider
                              authUser={user}
                              exchangeRates={exchangeRates}
                              project={project}
                            >
                              <UserConsumer>
                                {({ isFetchingUser, user }) => (
                                  <OrderProvider
                                    user={user}
                                    project={project}
                                    exchangeRates={exchangeRates}
                                  >
                                    <OrderConsumer>
                                      {({ isFetchingOrder }) => {
                                        const isLoading =
                                          isFetchingProject ||
                                          isFetchingExchangeRates ||
                                          isFetchingUser
                                        if (isLoading) {
                                          return <StokrLoader />
                                        } else {
                                          return (
                                            <CheckboxProvider>
                                              <Routes>
                                                {routes.map((route) => (
                                                  <Route
                                                    key={route.path}
                                                    path={route.path}
                                                    element={route.element}
                                                  />
                                                ))}
                                              </Routes>
                                            </CheckboxProvider>
                                          )
                                        }
                                      }}
                                    </OrderConsumer>
                                  </OrderProvider>
                                )}
                              </UserConsumer>
                            </UserProvider>
                          )
                        : () =>
                            isFetchingUser ? (
                              <StokrLoader />
                            ) : (
                              <LoginWithOTPFlow withBackground />
                            )}
                    </ExchangeRatesConsumer>
                  </ExchangeRatesProvider>
                )}
              </ProjectConsumer>
            </ProjectProvider>
          )}
        </AuthConsumer>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
