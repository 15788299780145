import { createContext, useState } from 'react'
import CheckboxClass from './Checkbox'

export const CheckboxContext = createContext()

const CheckboxProvider = ({ children, ...props }) => {
  const [checkedCheckboxes, setcheckedCheckboxes] = useState([])

  const setCheckedCheckbox = (event) => {
    const { name, checked } = event.target

    if (checked) {
      setcheckedCheckboxes([...checkedCheckboxes, name])
    } else {
      //remove label from array
      setcheckedCheckboxes(checkedCheckboxes.filter((label) => label !== name))
    }
  }

  const checkCheckbox = async (data) => {
    try {
      const result = await CheckboxClass.checkCheckbox(data)
      return result
    } catch (error) {
      console.log(`Error checking checkbox ${error}`)
    }
  }

  const getCheckbox = async (data) => {
    try {
      const result = await CheckboxClass.getCheckbox(data)
      return result
    } catch (error) {
      console.log(`Error fetching checkbox: ${error}`)
    }
  }

  const getCheckboxGroup = async (data) => {
    try {
      const result = await CheckboxClass.getCheckboxGroup(data)
      return result
    } catch (error) {
      console.log(`Error fetching checkbox group: ${error}`)
    }
  }

  const contextValue = {
    checkCheckbox: (data) => checkCheckbox(data),
    getCheckbox: (data) => getCheckbox(data),
    getCheckboxGroup: (data) => getCheckboxGroup(data),
    checkedCheckboxes,
    setCheckedCheckbox,
  }

  return (
    <CheckboxContext.Provider value={contextValue}>
      {children}
    </CheckboxContext.Provider>
  )
}

export default CheckboxProvider
