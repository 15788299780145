import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Table = styled.div`
  display: block;
  border: 1px solid ${props => props.theme.cLightGrey};
  ${props =>
    props.filled &&
    `
  background:  #e1e1e1; //${props => props.theme.cLightGrey};
  `}
`

export const Row = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  padding: 16px 24px;
  display: block;

  ${rwd.XLarge`
    padding: 16px 46px;
  `}

  & + & {
    border-top: 1px solid ${props => props.theme.cLightGrey};
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
  ${props =>
    props.smallPadding &&
    `
  padding:  14px 6px;
  `}
`

export const Label = styled.div`
  float: left;
  max-width: 50%;
  text-align: start;
`

export const Value = styled.div`
  float: right;
  display: grid;
  max-width: 50%;
`

export const LabelWarning = styled.div`
  float: left;
  text-align: left;
  width: 100%;
  color: #ee220d;
  line-height: 16px;
`

export const Caption = styled.div`
  text-transform: initial;
  font-size: 11px;
  float: right;
  letter-spacing: 0.6px;
  font-style: italic;
  //position: absolute;
  //right: 7px;
  //bottom: 0px;
`
