export const parseBlockContent = blockContent => {
  const parsedContent = {}

  if (blockContent) {
    blockContent.forEach(item => {
      const indexOfUnderscore = item.label.indexOf('_')
      const withoutBlockName = item.label.substring(indexOfUnderscore + 1)

      parsedContent[withoutBlockName] = {
        type: item.type,
        body: item.body,
        uri: item.uri,
      }
    })
  }

  return parsedContent
}

export default parseBlockContent
