import React, { useState, useContext, Fragment, useEffect } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { Modal, ModalInner, ModalBack } from 'components/Modal/Modal'
import Text from 'components/Text/Text.styles'
import {
  AccountBalance,
  BackButton,
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  StepController,
  StepControllerProvider,
  StepControllerConsumer,
  StokrLoader,
  Switch,
  CryptoAddress,
} from '@stokr/components-library'
import { Formik } from 'formik'
import Form from 'components/Form/Form'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import { SwitchInTitleWrapper } from 'styles/Pages/common.styles'
import {
  SignSubIntro,
  SignSubConnectBitfinex,
  SignSubConnectMetamask,
  SignSubTransactionMetamask,
  SignSubSendMetamask,
  SignSubStep,
} from 'components/SigningSubflow'
import * as urls from 'constants/paths'
import { ExchangeRatesContext } from 'context/ExchangeRates/ExchangeRatesContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { OrderContext } from 'context/Order/OrderContext'

const InvestSigningSubflowPage = () => {
  const { exchangeRates } = useContext(ExchangeRatesContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { order } = useContext(OrderContext)
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const [wallet] = useState(null)
  const navigator = useCustomNav()

  useEffect(() => {
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 1,
        activeIndex: 2,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <Row main>
        <Column part={8}>
          <ComponentWrapper>
            <BackButton>BACK</BackButton>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <InfoPanel />
          </ComponentWrapper>

          <ComponentWrapper borderBottom>
            <SectionTitle>
              Your account balance
              <SwitchInTitleWrapper right>
                <Switch
                  value="AFTER"
                  value1="BEFORE"
                  value2="AFTER"
                  handleChange={() => {}}
                />
              </SwitchInTitleWrapper>
            </SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPadding borderBottom>
            <Row>
              <Column part={8}>
                <ComponentWrapper>
                  <AccountBalance
                    title="Ether balance"
                    value={user.totalEtherBalance}
                    unit="ETH"
                    eqValue={
                      user.totalEtherBalance *
                      exchangeRates.pricesinEURorUSD.ETH
                    }
                    eqUnit="EUR"
                  />
                </ComponentWrapper>
              </Column>
            </Row>
          </ComponentWrapper>

          <ComponentWrapper borderBottom>
            <SectionTitle>Your selected address</SectionTitle>
          </ComponentWrapper>

          <Formik
            initialValues={{
              userAddresses: user.investor_eth_addresses,
            }}
          >
            {({ values }) => {
              return (
                <Form>
                  {values.userAddresses.map((account, i) =>
                    user.selectedAddress === account.address ? (
                      <CryptoAddress
                        key={i}
                        title={account.name}
                        address={account.address}
                        status={account.isWhitelisted ? '' : 'pending'}
                        data={{
                          value: account.balance.ether,
                          unit: 'ETH',
                          eqValue: account.balance.euro,
                          eqUnit: project.denominationSymbol,
                        }}
                        info={{
                          value: order.tokenAmount + ' TOKENS',
                          eqValue: (
                            (order.tokenAmount * order.tokenPrice) /
                            100
                          ).toFixed(2),
                          eqUnit: project.denominationSymbol,
                        }}
                        radio={{
                          id: 'investorAddress',
                          name: 'investorAddress',
                          value: 'SignSub 02', // account.address,
                          onChange: () => {},
                          onBlur: () => {},
                          checked: true,
                        }}
                        wrapped
                        borderBottom
                      />
                    ) : (
                      ''
                    ),
                  )}
                </Form>
              )
            }}
          </Formik>

          <ComponentWrapper grow />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>Confirm investment</h3>
              <p>
                {
                  'In this step, we guide you through finalizing your investment which involves signing and sending the investment transaction. To continue, please click ‘Sign & Send transaction’ and follow the instructions.'
                }
              </p>
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Button fluid>{'Sign & send transaction'}</Button>
          </ComponentWrapper>
        </Column>
      </Row>
      <Modal thin fullscreen isOpen>
        <ModalInner noPadding>
          <StepControllerProvider>
            <StepControllerConsumer>
              {(stepController) => {
                const prevStepIndex = stepController.activeStepIndex - 1

                return (
                  <Fragment>
                    <ModalBack
                      onClick={() =>
                        this.onBackButtonClick(stepController, prevStepIndex)
                      }
                      hide={!wallet}
                    />
                    <StepController
                      stepsProgressList={[
                        {
                          id: 'wallet',
                          handleClick: () => {
                            stepController.changeStep('wallet', 0)
                            this.setWallet(null)
                          },
                        },
                        {
                          id: 'connect',
                          handleClick: () => {
                            stepController.changeStep('connect', 1)
                          },
                        },
                        {
                          id: 'transaction',
                          handleClick: () => {
                            stepController.changeStep('transaction', 2)
                          },
                        },
                        {
                          id: 'send',
                          handleClick: () => {
                            stepController.changeStep('send', 3)
                          },
                        },
                      ]}
                      {...stepController}
                    >
                      <SignSubIntro
                        id="wallet"
                        changeStep={() =>
                          stepController.changeStep('connect', 1, 0)
                        }
                        setWallet={this.setWallet}
                      />

                      <SignSubStep id="connect">
                        {wallet === 'ledger' && (
                          <SignSubConnectBitfinex
                            changeStep={() =>
                              stepController.changeStep('transaction', 2, 1)
                            }
                          />
                        )}
                        {wallet === 'metamask' && (
                          <SignSubConnectMetamask
                            changeStep={() =>
                              stepController.changeStep('transaction', 2, 1)
                            }
                          />
                        )}
                      </SignSubStep>

                      <SignSubStep id="transaction">
                        {wallet === 'metamask' && (
                          <SignSubTransactionMetamask
                            changeStep={() =>
                              stepController.changeStep('send', 3, 2)
                            }
                          />
                        )}
                      </SignSubStep>

                      <SignSubStep id="send">
                        {wallet === 'metamask' && (
                          <SignSubSendMetamask
                            changeStep={() => navigator.navigate(urls.SUCCESS)}
                            tryAgain={() =>
                              stepController.changeStep('transaction', 2, 1)
                            }
                          />
                        )}
                      </SignSubStep>
                    </StepController>
                  </Fragment>
                )
              }}
            </StepControllerConsumer>
          </StepControllerProvider>
        </ModalInner>
        {/* </DisplayOnBreakpoint> */}
      </Modal>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default InvestSigningSubflowPage
