import React, { useState, useContext, useEffect } from 'react'
//import PropTypes from 'prop-types'
import {
  Button,
  ComponentWrapper,
  ComponentScroll,
  CryptoAddressWrapper,
  FAQ,
  SectionTitle,
  Tabs,
  Tab,
  CryptoAddress,
} from '@stokr/components-library'

import { Formik } from 'formik'
import Form from 'components/Form/Form'
// import { StokrLoader } from '@stokr/components-library'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
import { getMetamaskAddress } from 'utils/walletutils'
import { OrderConsumer, OrderContext } from 'context/Order/OrderContext'
import { UserConsumer, UserContext } from 'context/User/UserContext'
import { ProjectConsumer, ProjectContext } from 'context/Project/ProjectContext'
import getNetworkById from 'utils/get-network-name'
import fixDecimals from 'utils/fix-decimals'
// import Order from 'context/Order/Order'
// import {
//   ExchangeRatesConsumer,
//   ExchangeRatesContext,
// } from 'context/ExchangeRates/ExchangeRatesContext'
// import Order from 'context/Order/Order'

const REACT_APP_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID).toString()

const SignSubConnectMetamask = ({ changeStep, goBack }) => {
  const [tab, setTab] = useState(1)
  const [shouldRenderPage, setShouldRenderPage] = useState(false)

  const { user, setSelectedAddress } = useContext(UserContext)
  const { project } = useContext(ProjectContext)

  const onTryAgainClick = () => {
    goBack()
  }

  const handleSetTab = (tab) => {
    setTab(tab)
  }

  useEffect(() => {
    initializeMetamask()
  }, [])

  const initializeMetamask = async () => {
    // If MetaMask network is wrong
    if (REACT_APP_CHAIN_ID !== window.ethereum.networkVersion) {
      handleSetTab(3)
      setShouldRenderPage(true)
    } else {
      try {
        window.ethereum.on('accountsChanged', async () => {
          const metamaskAddress = await getMetamaskAddress()

          if (!user.selectedAddress && !project.isEthereum) {
            setSelectedAddress(metamaskAddress)
            changeStep()
          } else if (
            metamaskAddress.toString().toLowerCase() ===
              user.selectedAddress.address.toLowerCase() ||
            !project.isEthereum
          ) {
            changeStep()
          }
        })

        const metamaskAddress = await getMetamaskAddress()

        if (metamaskAddress.code === 4001) {
          // Connection was rejected

          handleSetTab(1)
          setShouldRenderPage(true)
        } else if (!project.isEthereum) {
          setSelectedAddress(metamaskAddress)
          changeStep()
        } else if (
          // Addresses don't match
          metamaskAddress.toString().toLowerCase() !==
            user.selectedAddress.address.toLowerCase() &&
          project.isEthereum
        ) {
          handleSetTab(2)
          setShouldRenderPage(true)
        } else {
          changeStep()
        }
      } catch (error) {
        handleSetTab(1)
        // Ready to render the page
        setShouldRenderPage(true)
      }
    }
  }

  //event to listen network changes on metamask
  window.ethereum.on('networkChanged', function (networkId) {
    if (REACT_APP_CHAIN_ID === networkId) {
      initializeMetamask()
    }
  })

  return (
    <>
      {shouldRenderPage ? (
        <ModalWrapper>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentScroll>
            <Tabs activeTab={tab}>
              <Tab tabId={1}>
                <ComponentWrapper noPaddingVertical>
                  <SignSubText>
                    <h3>OOPS... WE CAN'T CONNECT</h3>
                    <p>
                      Is your MetaMask connection installed and activated in
                      your browser?
                      <br />
                      <br />
                      Please check and try again.
                      <br />
                      <br />
                      Having issues?{' '}
                      <a href="mailto:help@stokr.io">help@stokr.io</a>
                    </p>
                  </SignSubText>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <FAQ
                    items={[
                      {
                        title: 'How do i change addresses?',
                        content:
                          'Aliquam orci nibh, convallis eu fringilla vel, pharetra non libero. Donec feugiat vehicula sem non sagittis. Fusce vitae purus nulla. Curabitur venenatis augue in augue venenatis placerat non a libero. Quisque facilisis porta libero ac aliquam. Vestibulum ipsum mauris, pretium sed felis et, posuere ultricies purus. Aliquam aliquet arcu a tincidunt mattis. Fusce a vestibulum magna.',
                      },
                    ]}
                  />
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <SectionTitle>My selected address</SectionTitle>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <Formik
                    initialValues={{
                      userAddresses: user.investor_eth_addresses,
                    }}
                  >
                    {() => {
                      return (
                        <>
                          {user.isBMN1 ? (
                            <Form>
                              <CryptoAddress
                                title={user.selectedAddress.name}
                                address={user.selectedAddress.address}
                                status={
                                  user.selectedAddress.isWhitelisted
                                    ? ''
                                    : 'pending'
                                }
                                data={{
                                  value: fixDecimals(
                                    user.totalCurrencyBalance,
                                    user.currencyDecimals,
                                  ),
                                  unit: user.currencySymbol,
                                  eqValue: fixDecimals(
                                    user.totalCurrencyBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                                info={{
                                  value: `${project.tokenSymbol} ${fixDecimals(
                                    user.totalTokenBalance,
                                    project.tokenRoundingDecimals,
                                  )}`,
                                  eqValue: fixDecimals(
                                    user.totalTokenBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                              />
                            </Form>
                          ) : (
                            <CryptoAddress
                              title={user.name}
                              status={''}
                              data={{
                                value: user.GAID,
                                shortAdress: true,
                                tooltip: true,
                              }}
                              info={{
                                value: `${project.tokenSymbol} ${fixDecimals(
                                  user.totalTokenBalance,
                                  project.tokenRoundingDecimals,
                                )}`,
                                eqValue: fixDecimals(
                                  user.totalTokenBalanceinEURorUSD,
                                  project.euroDecimals,
                                ),
                                eqUnit: `= ${project.denominationSymbol}`,
                                noEqualsToSign: 'true',
                              }}
                              wrapped
                              borderBottom
                            />
                          )}
                        </>
                      )
                    }}
                  </Formik>
                </ComponentWrapper>

                <ComponentWrapper center>
                  <Button minWidth="240px" onClick={onTryAgainClick}>
                    Try again
                  </Button>
                </ComponentWrapper>
              </Tab>
              <Tab tabId={2}>
                <ComponentWrapper noPaddingVertical>
                  <SignSubText>
                    <h3>OOPS... WRONG METAMASK ACCOUNT</h3>
                    <p>
                      Your active MetaMask account doesn't hold your selected
                      Ethereum address. Change your active account in Metamask
                      and the page will automatically be reloaded..
                      <br />
                      <br />
                      You can also start over and select or add a different
                      Ethereum address to make your investment.
                      <br />
                      <br />
                      Having issues?{' '}
                      <a href="mailto:help@stokr.io">help@stokr.io</a>
                    </p>
                  </SignSubText>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <SectionTitle>My selected address</SectionTitle>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <Formik
                    initialValues={{
                      userAddresses: user.investor_eth_addresses,
                    }}
                  >
                    {({ values }) => {
                      return (
                        <>
                          {project.isEthereum ? (
                            <Form>
                              <CryptoAddress
                                title={user.selectedAddress.name}
                                address={user.selectedAddress.address}
                                status={
                                  user.selectedAddress.isWhitelisted
                                    ? ''
                                    : 'pending'
                                }
                                data={{
                                  value: fixDecimals(
                                    user.totalCurrencyBalance,
                                    user.currencyDecimals,
                                  ),
                                  unit: user.currencySymbol,
                                  eqValue: fixDecimals(
                                    user.totalCurrencyBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                                info={{
                                  value: `${project.tokenSymbol} ${fixDecimals(
                                    user.totalTokenBalance,
                                    project.tokenRoundingDecimals,
                                  )}`,
                                  eqValue: fixDecimals(
                                    user.totalTokenBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                              />
                            </Form>
                          ) : (
                            <CryptoAddress
                              title={user.name}
                              status={''}
                              data={{
                                value: user.GAID,
                                shortAdress: true,
                                tooltip: true,
                              }}
                              info={{
                                value: `${project.tokenSymbol} ${fixDecimals(
                                  user.totalTokenBalance,
                                  project.tokenRoundingDecimals,
                                )}`,
                                eqValue: fixDecimals(
                                  user.totalTokenBalanceinEURorUSD,
                                  project.euroDecimals,
                                ),
                                eqUnit: `= ${project.denominationSymbol}`,
                                noEqualsToSign: 'true',
                              }}
                              wrapped
                              borderBottom
                            />
                          )}
                        </>
                      )
                    }}
                  </Formik>
                </ComponentWrapper>

                <ComponentWrapper center></ComponentWrapper>
              </Tab>

              <Tab tabId={3}>
                <ComponentWrapper noPaddingVertical>
                  <SignSubText>
                    <h3>OOPS... WRONG METAMASK NETWORK</h3>
                    <p>
                      {`Metamask points to ${getNetworkById(
                        window.ethereum.networkVersion,
                      )}. Please switch to ${getNetworkById(
                        REACT_APP_CHAIN_ID,
                      )} and the page will automatically be reloaded.`}
                      <br />
                      <br />
                      Having issues?{' '}
                      <a href="mailto:help@stokr.io">help@stokr.io</a>
                    </p>
                  </SignSubText>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <SectionTitle>My selected address</SectionTitle>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <Formik
                    initialValues={{
                      userAddresses: user.investor_eth_addresses,
                    }}
                  >
                    {({ values }) => {
                      return (
                        <>
                          {project.isEthereum ? (
                            <Form>
                              <CryptoAddress
                                title={user.selectedAddress.name}
                                address={user.selectedAddress.address}
                                status={
                                  user.selectedAddress.isWhitelisted
                                    ? ''
                                    : 'pending'
                                }
                                data={{
                                  value: fixDecimals(
                                    user.totalCurrencyBalance,
                                    user.currencyDecimals,
                                  ),
                                  unit: user.currencySymbol,
                                  eqValue: fixDecimals(
                                    user.totalCurrencyBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                                info={{
                                  value: `${project.tokenSymbol} ${fixDecimals(
                                    user.totalTokenBalance,
                                    project.tokenRoundingDecimals,
                                  )}`,
                                  eqValue: fixDecimals(
                                    user.totalTokenBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                              />
                            </Form>
                          ) : (
                            <CryptoAddress
                              title={user.name}
                              status={''}
                              data={{
                                value: user.GAID,
                                shortAdress: true,
                                tooltip: true,
                              }}
                              info={{
                                value: `${project.tokenSymbol} ${fixDecimals(
                                  user.totalTokenBalance,
                                  project.tokenRoundingDecimals,
                                )}`,
                                eqValue: fixDecimals(
                                  user.totalTokenBalanceinEURorUSD,
                                  project.euroDecimals,
                                ),
                                eqUnit: `= ${project.denominationSymbol}`,
                                noEqualsToSign: 'true',
                              }}
                              wrapped
                              borderBottom
                            />
                          )}
                        </>
                      )
                    }}
                  </Formik>
                </ComponentWrapper>

                <ComponentWrapper center></ComponentWrapper>
              </Tab>
            </Tabs>
          </ComponentScroll>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <ComponentWrapper>
            <SectionTitle>Invest in {project.name}</SectionTitle>
          </ComponentWrapper>

          <ComponentScroll>
            <Tabs activeTab={tab}>
              <Tab tabId={1}>
                <ComponentWrapper noPaddingVertical>
                  <SignSubText>
                    <h3>Connecting to MetaMask</h3>
                  </SignSubText>
                </ComponentWrapper>

                <ComponentWrapper />

                <ComponentWrapper borderTop borderBottom>
                  <CryptoAddressWrapper
                    refresh={
                      'Please proceed to MetaMask to finalise the transaction.'
                    }
                  >
                    <CryptoAddress
                      title="ethereum address"
                      address={''}
                      data={{
                        value: '',
                      }}
                    />
                  </CryptoAddressWrapper>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <SectionTitle>My selected address</SectionTitle>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom>
                  <Formik
                    initialValues={{
                      userAddresses: user.investor_eth_addresses,
                    }}
                  >
                    {() => {
                      return (
                        <>
                          {project.isEthereum ? (
                            <Form>
                              <CryptoAddress
                                title={user.selectedAddress.name}
                                address={user.selectedAddress.address}
                                status={
                                  user.selectedAddress.isWhitelisted
                                    ? ''
                                    : 'pending'
                                }
                                data={{
                                  value: fixDecimals(
                                    user.totalCurrencyBalance,
                                    user.currencyDecimals,
                                  ),
                                  unit: user.currencySymbol,
                                  eqValue: fixDecimals(
                                    user.totalCurrencyBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                                info={{
                                  value: `${project.tokenSymbol} ${fixDecimals(
                                    user.totalTokenBalance,
                                    project.tokenRoundingDecimals,
                                  )}`,
                                  eqValue: fixDecimals(
                                    user.totalTokenBalanceinEURorUSD,
                                    project.euroDecimals,
                                  ),
                                  eqUnit: project.denominationSymbol,
                                }}
                              />
                            </Form>
                          ) : (
                            <CryptoAddress
                              title={user.name}
                              status={''}
                              data={{
                                value: user.GAID,
                                shortAdress: true,
                                tooltip: true,
                              }}
                              info={{
                                value: `${project.tokenSymbol} ${fixDecimals(
                                  user.totalTokenBalance,
                                  project.tokenRoundingDecimals,
                                )}`,
                                eqValue: fixDecimals(
                                  user.totalTokenBalanceinEURorUSD,
                                  project.euroDecimals,
                                ),
                                eqUnit: `= ${project.denominationSymbol}`,
                                noEqualsToSign: 'true',
                              }}
                              wrapped
                              borderBottom
                            />
                          )}
                        </>
                      )
                    }}
                  </Formik>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom></ComponentWrapper>
              </Tab>
            </Tabs>
          </ComponentScroll>
        </ModalWrapper>
      )}
    </>
  )
}

SignSubConnectMetamask.propTypes = {
  //changeStep: PropTypes.func.isRequired,
}

export default SignSubConnectMetamask
