import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  ComponentScroll,
  ComponentWrapper,
  StokrLoader,
  SectionTitle,
} from '@stokr/components-library'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
import { OrderContext } from 'context/Order/OrderContext'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import Helmet from 'react-helmet'

const BtcPayCheckout = ({ invoiceId, onEnter, onLeave }) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  let { order } = useContext(OrderContext)
  let { project } = useContext(ProjectContext)
  let { user } = useContext(UserContext)

  useEffect(() => {
    setTimeout(() => {
      window.btcpay.showInvoice(invoiceId)
      window.btcpay.onModalWillEnter(onEnter)
      window.btcpay.onModalWillLeave(onLeave)
    }, 0)
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? '' : <StokrLoader />
}

BtcPayCheckout.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
}

export default BtcPayCheckout
