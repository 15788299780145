export default function(selectedCurrency, allCurrencies = {}) {
  const currencyIndex = allCurrencies.internalReference.findIndex(
    currency => currency === selectedCurrency,
  )

  if (currencyIndex === -1) {
    throw new Error('Currency details not found')
  }

  return {
    internalReference: allCurrencies.internalReference[currencyIndex],
    displayName: allCurrencies.displayNames[currencyIndex],
    symbol: allCurrencies.symbols[currencyIndex],
  }
}
