import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExchangeRates from './ExchangeRates'
import { withRouter } from 'utils/customNavigate'
import * as urls from 'constants/paths'

export const ExchangeRatesContext = React.createContext()

class ExchangeRatesProviderClass extends Component {
  state = {
    exchangeRates: undefined,
    isFetchingExchangeRates: true,
  }

  // etherPriceinEURorUSD = 180. how much euro you pay for 1 ether
  // dollarPriceinEURorUSD = 0.9 - how much euro you pay for 1 dollar
  // token price in euro = how much euro you pay for 1 token

  // xPriceInY = how much Y you pay for 1 x
  // Naming convention: tokenPriceInEther

  // In event db whenever you say "price" it means "price in euro"
  // /api/common/ether-price = it returns how much euro 1 ether costs

  componentDidUpdate() {
    const { project } = this.props
    const { hasStartedSetExchangeRate } = this.state

    if (project && !hasStartedSetExchangeRate) {
      this.setExchangeRates()
      // This ensures the if condition
      // will only run once
      this.setState({ hasStartedSetExchangeRate: true })
    }
  }

  setExchangeRates = async () => {
    let { project } = this.props

    project.denominationSymbol =
      project.tokenCurrency === 'USD'
        ? '$'
        : project.tokenCurrency === 'EUR'
        ? '€'
        : (() => {
            throw new Error('Invalid currency')
          })()

    try {
      const [etherPrice, BTCinUSD, exchangeRates] = await Promise.all([
        project.token_issuance_type === 'ethereum'
          ? ExchangeRates.getEtherPrice()
          : undefined,
        ExchangeRates.getBTCinUSD(),
        ExchangeRates.getExchangeRates(),
      ])

      const pricesinEURorUSD = {
        ETH: etherPrice,
        EUR: 1,
      }

      for (let [symbol, rate] of Object.entries(exchangeRates.rates)) {
        pricesinEURorUSD[symbol] = 1 / rate
      }

      if (project.denominationSymbol === '$') {
        let usdRate = pricesinEURorUSD['USD']
        for (let [symbol, rate] of Object.entries(pricesinEURorUSD)) {
          pricesinEURorUSD[symbol] = rate / usdRate
        }
        pricesinEURorUSD['BTC'] = BTCinUSD.USD
      } else {
        pricesinEURorUSD['BTC'] = BTCinUSD.USD * pricesinEURorUSD['USD']
      }
      this.setState({
        exchangeRates: { pricesinEURorUSD, bmn1inBTC: 7.7 },
        bitFinexPayLimit: 2000000,
        isFetchingExchangeRates: false,
      })
    } catch (error) {
      this.props.navigate(urls.OOPS, {
        state: {
          message: 'Can not reach the server. Please contact support@stokr.io',
        },
      })

      this.setState({
        isFetchingExchangeRates: false,
      })
      console.error(error)
    }
  }

  updateEtherPrice = async () => {
    const { exchangeRates } = this.state

    try {
      const etherPrice = (await ExchangeRates.getEtherPrice()).currentEtherPrice

      this.setState({
        exchangeRates: {
          ...exchangeRates,
          pricesinEURorUSD: {
            ...exchangeRates.pricesinEURorUSD,
            ETH: etherPrice,
          },
        },
      })

      return etherPrice
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { children } = this.props

    return (
      <ExchangeRatesContext.Provider
        value={{
          ...this.state,
          updateEtherPrice: this.updateEtherPrice,
        }}
      >
        {children}
      </ExchangeRatesContext.Provider>
    )
  }
}

ExchangeRatesProviderClass.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ExchangeRatesProvider = withRouter(ExchangeRatesProviderClass)

export const ExchangeRatesConsumer = ExchangeRatesContext.Consumer
