import styled from 'styled-components/macro'
import { ComponentWrapper } from '@stokr/components-library'
import rwd from 'styles/rwd'

export const Wrapper = styled(ComponentWrapper)`
  background: ${props => props.theme.cPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: -1px;
`

export const Title = styled.div`
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  margin-bottom: 14px;
  color: ${props => props.theme.cWhite};
`

export const Amount = styled.div`
  font-size: 34px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 2px;
  color: ${props => props.theme.cWhite};
  white-space: nowrap;

  ${rwd.Medium`
    font-size: 28px;
  `}

  ${rwd.Large`
    font-size: 34px;
  `}
`
